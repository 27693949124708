import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import { Formik } from "formik";
import * as Yup from 'yup';
// ----------------------------------------------------------------------
const phoneRegExp= /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const SignUpSchema= Yup.object().shape({
    mob: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10, 'Mobile No is 10 Digit Number').max(10, 'Mobile No is 10 Digit Number').required('Required'),
    aadhar:Yup.string().matches(phoneRegExp, 'Addhar number is not valid').min(12, 'Addhar is 12 Digit Number').max(12, 'Addhar is 12 Digit Number').required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  });
export default function SignupForm({context,callback}) {
  const { signUpAPI }=useContext(context);
  const [showPassword, setShowPassword] = useState(false);

  

  return (
    <>
      <Formik
        initialValues={{ fname:"",address:"",mob:"",aadhar:"", email: "", password: "" }}
        validationSchema={SignUpSchema}
        onSubmit={(values, { setSubmitting }) => {
          signUpAPI(values,callback);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (<>
            <Stack spacing={3}>
               <TextField 
              name="fname" 
              label="Name" 
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fname}
              required
              />  
               <TextField 
              name="address" 
              label="Address" 
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              required
              />
               <TextField 
              name="mob" 
              label="Mobile No" 
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mob}
              required
              />
              {errors.mob && touched.mob && errors.mob}
               <TextField 
              name="aadhar" 
              label="Aadhar" 
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.aadhar}
              required
              />
              {errors.aadhar && touched.aadhar && errors.aadhar}
              <TextField 
              name="email" 
              label="Email address" 
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              />
              {errors.email && touched.email && errors.email}
              <TextField
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                required
              />
               {errors.password &&
                            touched.password &&
                            errors.password}
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              {/* <Checkbox name="remember" label="Remember me" />
              <Link variant="subtitle2" underline="hover">
                Forgot password?
              </Link> */}
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              Sign Up
            </LoadingButton>
            </>
        )}
      </Formik>
    </>
  );
}
