import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react'



export default function CPDF({products,data,callback}) {
    const styles = StyleSheet.create({
        table: { 
            // marginTop:20,
          display: "table", 
          width: 380, 
          borderStyle: "solid", 
          borderWidth: 1, 
          borderRightWidth: 1, 
          borderBottomWidth: 1 ,
          height:50,
        }, 
        tableRow: { 
          margin: "auto", 
          marginTop: 0, 
          flexDirection: "row",
          borderStyle: "solid", 
          borderBottomWidth: 1, 
        }, 
        tableCol: {  
          borderStyle: "solid", 
          // borderWidth: 1, 
          borderRightWidth: 1, 
          // borderTopWidth: 1 
        }, 
        tableCell: { 
          margin: "auto", 
          marginTop: 0, 
          fontSize: 9
        },
        tableHeader:{
            color:"black",
            fontSize:9
        },
        pShell:{
            width: "30%"
        },
        qShell:{
            width:"10%"
        },
        wShell:{
            width:"10%"
        },
        AShell:{
            width:"20%"
        }
      });
      useEffect(()=>{
        handleCalculation();
      },[]);
      const [T5,setT5]=useState(0.00);
      const [T12,setT12]=useState(0.00);
      const [T18,setT18]=useState(0.00);
      const [T28,setT28]=useState(0.00);

      const [TG5,setTG5]=useState(0.00);
      const [TG12,setTG12]=useState(0.00);
      const [TG18,setTG18]=useState(0.00);
      const [TG28,setTG28]=useState(0.00);

      const [T,setT]=useState(0.00);
      const [TG,setTG]=useState(0.00);
      const [TSG,setTSG]=useState(0.00);
      const [TCG,setTCG]=useState(0.00);
      const [TIG,setTIG]=useState(0.00);

      const handleCalculation=()=>{
        let t5=0;
        let t12=0;
        let t18=0;
        let t28=0;

        let tg5=0;
        let tg12=0;
        let tg18=0;
        let tg28=0;

        products.forEach((item)=>{
            const gst=Math.round((parseFloat(item.gst_per).toFixed(2)*100)/parseFloat(item.price));
            if(gst == 5){
                t5= t5 + parseFloat(item.total_price);
                tg5= tg5 + parseFloat(item.gst_per)*parseInt(item.qty);
            }else if( gst == 12){
                t12= t12 + parseFloat(item.total_price);
                tg12= tg12 + parseFloat(item.gst_per)*parseInt(item.qty);
            }else  if( gst == 18){
                t18= t18 + parseFloat(item.total_price);
                tg18= tg18 + parseFloat(item.gst_per)*parseInt(item.qty);
            }else  if( gst == 28){
                t28= t28 + parseFloat(item.total_price);
                tg28= tg28 + parseFloat(item.gst_per)*parseInt(item.qty);
            }else{

            }
        })
        setT5(t5.toFixed(2))
        setT12(t12.toFixed(2))
        setT18(t18.toFixed(2))
        setT28(t28.toFixed(2))
        setT((t5+t12+t18+t28).toFixed(2))

        setTG5(tg5.toFixed(2))
        setTG12(tg12.toFixed(2))
        setTG18(tg18.toFixed(2))
        setTG28(tg28.toFixed(2))
        setTG((tg5+tg12+tg18+tg28).toFixed(2))

        let tsgst=parseFloat(handleStateCheck(tg5).sgst)+parseFloat(handleStateCheck(tg12).sgst)+parseFloat(handleStateCheck(tg18).sgst)+parseFloat(handleStateCheck(tg28).sgst);
        let tcgst=parseFloat(handleStateCheck(tg5).cgst)+parseFloat(handleStateCheck(tg12).cgst)+parseFloat(handleStateCheck(tg18).cgst)+parseFloat(handleStateCheck(tg28).cgst);
        let tigst=parseFloat(handleStateCheck(tg5).igst)+parseFloat(handleStateCheck(tg12).igst)+parseFloat(handleStateCheck(tg18).igst)+parseFloat(handleStateCheck(tg28).igst);
       

        setTSG(tsgst.toFixed(2));
        setTCG(tcgst.toFixed(2));
        setTIG(tigst.toFixed(2));

        console.log("Hello I am IN");

        callback({
            sgst:tsgst.toFixed(2),
            cgst:tcgst.toFixed(2),
            igst:tigst.toFixed(2)
        })

      }

      const handleStateCheck=(v)=>{
        let value=Math.round(v);
        if(data.c_state == "Odisha"){
           return {
                cgst:(value/2).toFixed(2),
                sgst:(value/2).toFixed(2),
                igst:0.00
           }
        }else{
           return {
            cgst:0.00,
            sgst:0.00,
            igst:value.toFixed(2)
           } 
        }
      }

     

  return (
    <View style={styles.table}>
       <View style={styles.tableRow}> 
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>CLASS</Text> 
          </View> 
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>TOTAL</Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>SGST</Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>CGST</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>IGST</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell,{borderRightWidth: 0}]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>TOTAL GST</Text> 
          </View> 
        </View>
        <View style={styles.tableRow}> 
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell]}>GST 5%</Text> 
          </View> 
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell]}>{T5}</Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell]}>{handleStateCheck(TG5).sgst}</Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell]}>{handleStateCheck(TG5).cgst}</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell]}>{handleStateCheck(TG5).igst}</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell,{borderRightWidth: 0}]}> 
            <Text style={[styles.tableCell]}>{TG5}</Text> 
          </View> 
        </View>
        <View style={styles.tableRow}> 
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell]}>GST 12%</Text> 
          </View> 
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell]}>{T12}</Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell]}>{handleStateCheck(TG12).sgst}</Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell]}>{handleStateCheck(TG12).cgst}</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell]}>{handleStateCheck(TG12).igst}</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell,{borderRightWidth: 0}]}> 
            <Text style={[styles.tableCell]}>{TG12}</Text> 
          </View> 
        </View>
        {/* <View style={styles.tableRow}> 
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell]}>GST 18%</Text> 
          </View> 
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell]}>{T18}</Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell]}>{handleStateCheck(TG18).sgst}</Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell]}>{handleStateCheck(TG18).cgst}</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell]}>{handleStateCheck(TG18).igst}</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell]}>{TG18}</Text> 
          </View> 
        </View>
        <View style={styles.tableRow}> 
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell]}>GST 28%</Text> 
          </View> 
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell]}>{T28}</Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell]}>{handleStateCheck(TG28).sgst}</Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell]}>{handleStateCheck(TG28).cgst}</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell]}>{handleStateCheck(TG28).igst}</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell]}>{TG28}</Text> 
          </View> 
        </View> */}
        <View style={[styles.tableRow,{borderBottomWidth: 0}]}> 
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>TOTAL</Text> 
          </View> 
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>{T}</Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>{TSG}</Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>{TCG}</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>{TIG}</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell,{borderRightWidth: 0}]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>{TG}</Text> 

          </View> 
        </View>
    </View>
  )
}
