import React from 'react'

import { styled } from '@mui/material'


const Container=styled("div")({

})
const Table=styled("table")({
    border:"1px solid black",
    borderCollapse:"collapse",
    width:"100%"
})
const THead=styled("th")({
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:18,
    fontWeight:"bold"
})

const TData=styled("td")({
    padding:0,
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:12,
    fontWeight:"bold",
    textAlign:"center"
})


const TranReportTable = ({data}) => {
   const handleTotal=(array)=>{
    let totalCredit=0;
    let totalDebit=0;
    array.map((item)=>{
                let debt="";
                let cred="";
                let name="";
                if(item.paymentData.length >= 0){
                    debt=item.amount;
                    name=item.salesData.name
                }else{
                    cred=item.amount;
                    name=item.paymentData.payment_cus_name
                }
                if(debt != ""){
                    totalDebit=totalDebit+parseFloat(debt);
                }
                if(cred != ""){
                    totalCredit=totalCredit+parseFloat(cred);
                }
                

    })
    return {credit:totalCredit,debit:totalDebit};
   }
  return (
    <Container>
    <Table>
      <thead>
          <tr>
              <THead>#</THead>
              <THead>Date</THead>
              <THead>Customer</THead>
              <THead>Particulars</THead>
              <THead>Debit</THead>
              <THead>Credit</THead>
          </tr>
      </thead>
      <tbody>
          {
              data.map((item,i)=>{ 
                let debt="";
                let cred="";
                let name="";
                if(item.paymentData.length >= 0){
                    debt=item.amount;
                    name=item.salesData.name
                }else{
                    cred=item.amount;
                    name=item.paymentData.payment_cus_name
                }
                return (
                  <tr>
                      <TData style={{textAlign: "center"}}>{i+1}</TData>
                      <TData>{item.main_date}</TData>
                      <TData>{name}</TData>
                      <TData>{item.tag+item.ex}</TData>
                      <TData>{debt}</TData>
                      <TData>{cred}</TData>
                  </tr>
              )}
              )
          }
          <tr>
                      <TData style={{textAlign: "center"}}></TData>
                      <TData></TData>
                      <TData></TData>
                      <TData></TData>
                      <TData>{handleTotal(data).debit.toFixed(2)}</TData>
                      <TData>{handleTotal(data).credit.toFixed(2)}</TData>
                  </tr>
      </tbody>
    </Table>
  </Container>
  )
}

export default TranReportTable
