import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useContext, useEffect, useState } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  TextField,
  InputAdornment,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
// components
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
// mock
import USERLIST from "../_mock/user";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker as DatePick } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import {
  Document,
  PDFDownloadLink,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Customer Name", alignRight: false },
  { id: "invoice_no", label: "Invoice No", alignRight: false },
  { id: "tot_amt", label: "Total Amount", alignRight: false },
  { id: "cash_amount", label: "Cash Amount", alignRight: false },
  { id: "online", label: "Online", alignRight: false },
  { id: "collection", label: "Collection", alignRight: false },
  { id: "order_by", label: "Order By", alignRight: false },
];

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    margin: 20,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: "100%",
  },
});

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const SignUpSchema = Yup.object().shape({
  mob: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Mobile No is 10 Digit Number")
    .max(10, "Mobile No is 10 Digit Number")
    .required("Required"),
  aadhar: Yup.string()
    .matches(phoneRegExp, "Addhar number is not valid")
    .min(12, "Addhar is 12 Digit Number")
    .max(12, "Addhar is 12 Digit Number")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.invoice_no.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ReturnReport({ context }) {
  const [open, setOpen] = useState(null);
  const {
    employeeList,
    getEmployee,
    user,
    stateUpdate,
    getInventory,
    updateUserAPI,
    paymentsAPI,
    invoicesAPI,
    invoices,
    payments,
    INVproducts,
    getProductsInvoiceAPI,
    unitProvider,
    customers,
    getCustomer,
    getReturnProducts,
    INVRproducts
  } = useContext(context);

  const [inv, setInv] = useState([]);

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    // if (user.user_type != 1) {
    //   navigate("/app");
    // } else {
    getEmployee();
    getInventory();
    paymentsAPI();
    invoicesAPI((e) => {
      // setInv(e);
    });
    getCustomer();
    getProductsInvoiceAPI("");
    getReturnProducts();
    // }
  }, []);
  const updateList = async () => {
    await getEmployee();
    await paymentsAPI();
    await invoicesAPI((e) => {
      setInv(e);
    });
    await getCustomer();
    await  getReturnProducts();
  };

  //
  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();
  const [salesman, setSalesman] = useState(()=>{
    if(user.user_type == 1){
      return 0;
    }
    return user.id
  });

  const PaymentProvider = (invoice_id) => {
    let cashpay = 0;
    let online = 0;
    payments
      .filter((i) => i.invoice_id === invoice_id)
      .forEach((item) => {
        if (item.payment_mode === "CASH") {
          cashpay = cashpay + parseFloat(item.payment_paid);
        } else {
          online = online + parseFloat(item.payment_paid);
        }
      });
    const obj = {
      cash: cashpay,
      online: online,
    };
    return obj;
  };

  const salesmanProvider = (id) => {
    if (id != 0) {
      const a = customers.filter((item) => item.c_id === id);
      return a[0].c_name;
    }
    return "ALL";
  };

  //
  const numberOfInvoiceProvider = (emp_id) => {
    let total = 0;
    const data = invoices.filter((item) => {
      let invoiceDate = new Date(item.created_at);
      return (
        item.id === emp_id &&
        invoiceDate >= startDate &&
        invoiceDate <= endDate
      );
    });
    if (data.length > 0) {
      data.map((item) => {
        total = total + parseFloat(item.total);
      });
    }
    const obj = {
      length: data.length,
      total: total.toFixed(2),
    };
    return obj;
  };

  const handleDateChage = (date) => {
    if (date !== null) {
      setStartDate(date[0]);
      setEndDate(date[1]);
    } else {
      setStartDate(new Date());
      setEndDate(new Date());
    }
    updateList();
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [userID, setuserId] = useState(0);

  const handleOpenMenu = (event, data) => {
    setuserId(data);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setuserId(0);
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = inv.map((n) => n.full_name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - inv.length) : 0;

  const filteredUsers = applySortFilter(
    inv,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers.length && !!filterName;
  const [daily,setDaily]=useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {setOpenModal(false);
    setDaily(false);
  };
  const [showPassword, setShowPassword] = useState(false);

  const PDFTYU=()=>{ return (
    <Document title={daily?"Daily Payment Report":"Return Report"}>
              <Page size="A4" style={styles.page}>
                <View
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{ fontSize: 10, fontWeight: "black", width: "30%" }}
                  >
                    {currDate + " " + currTime}
                  </Text>
                  <Text
                    style={{ fontSize: 18, fontWeight: "black", width: "30%" }}
                  >
                  {daily?"Payment Report":"Return Report"}
                  </Text>
                  <View
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "right",
                    }}
                  >
                    <Text style={{ fontSize: 14, fontWeight: "black" }}>
                      SMILE NAMKEEN
                    </Text>
                    <Text style={{ fontSize: 10, fontWeight: "black" }}>
                      Markona,Balasore
                    </Text>
                    <Text style={{ fontSize: 9, fontWeight: "black" }}>
                      8249239051
                    </Text>
                  </View>
                </View>
                <View style={{ marginLeft: 2 }}>
                  <Text
                    style={{
                      fontSize: 11,
                      fontWeight: "black",
                    }}
                  >
                    Form Date: {startDate.toLocaleDateString()}
                  </Text>
                  <Text
                    style={{
                      fontSize: 11,
                      fontWeight: "black",
                    }}
                  >
                    To Date: {endDate.toLocaleDateString()}
                  </Text>
                  <Text
                    style={{
                      fontSize: 11,
                      fontWeight: "black",
                    }}
                  >
                    Customer:{salesmanProvider(salesman)}
                  </Text>
                  <Text
                    style={{
                      fontSize: 11,
                      fontWeight: "black",
                    }}
                  >
                    No of Invoice:{inv.length}
                  </Text>
                </View>
                <TablePdf inv={inv} PaymentProvider={PaymentProvider}/>
                <ProductOrderTable inv={inv} allInvoiceProduct={INVRproducts} unit={unitProvider} />
                
              </Page>
            </Document>
  )};

  const handleDatePickChange=(date) => {
    console.log(date);
    if (date != null) {
      const data = invoices.filter((item) => {
        let invoiceDate = new Date(item.created_at);
        if (salesman != 0) {
          return (
            item.customer_id === salesman &&
            invoiceDate >= date[0] &&
            invoiceDate <= date[1] && 
            item.return_once != "0"
          );
        }
        return invoiceDate >= date[0] && invoiceDate <= date[1] && 
        item.return_once != "0" ;
      });

      setInv(data);
      setStartDate(date[0]);
      setEndDate(date[1]);
    } else {
      setStartDate(new Date());
      setEndDate(new Date());
    }
  }

  return (
    <>
      <Helmet>
        <title> Return Report | Shree Smile </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
          Return Report
          </Typography>
          {/* <Button
            variant="contained"
            startIcon={<Iconify icon="mdi:refresh" />}
            onClick={updateList}
          >
            Refresh
          </Button> */}
        </Stack>
        <Card style={{ marginBottom: "10px", padding: "5px" }}>
          <Typography variant="h6" gutterBottom>
            Invoice Filter
          </Typography>
          <Box
            style={{
              display: "flex",
              padding: 5,
              justifyContent: "space-evenly",
              flexWrap: "wrap",
              marginBottom: 5,
              marginTop: 5,
              gap: 5,
            }}
          >
            <FormControl sx={{ minWidth: 160 }}>
              <InputLabel id="demo-simple-select-label">Customer</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Customer"
                name="Customer"
                onChange={(v) => {
                  const value = v.target.value;
                  setSalesman(v.target.value);
                  if (value != 0) {
                    const a = invoices.filter((item) => {
                      let invoiceDate = new Date(item.created_at);
                      return (
                        item.customer_id === value &&
                        invoiceDate >= startDate &&
                        invoiceDate <= endDate && 
                        item.return_once != "0"
                      );
                    });
                    setInv(a);
                  } else {
                    const data = invoices.filter((item) => {
                      let invoiceDate = new Date(item.created_at);
                      return invoiceDate >= startDate && invoiceDate <= endDate && 
                      item.return_once != "0";
                    });

                    setInv(data);
                  }
                }}
                value={salesman}
              >
                <MenuItem value={0}>All</MenuItem>
                {customers.map((item) => (
                  <MenuItem value={item.c_id}>{item.c_name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <DatePick
              format="yyyy-MM-dd HH:mm:ss"
              defaultCalendarValue={[startDate, endDate]}
              onChange={handleDatePickChange}
            />
            <LoadingButton
              sx={{ backgroundColor: "blue", color: "white" }}
              onClick={() => {
                handleOpenModal();
              }}
            >
              Download Return Report
            </LoadingButton>
            {/* <LoadingButton
              sx={{ backgroundColor: "blue", color: "white" }}
              onClick={() => {
                handleOpenModal();
                setDaily(true);
              }}
            >
              Download Daily Order Report
            </LoadingButton> */}
          </Box>
        </Card>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={inv.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const selectedUser =
                        selected.indexOf(row.invoice_no) !== -1;
                      return (
                        <TableRow
                          hover
                          key={row.invoice_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedUser}
                              onChange={(event) =>
                                handleClick(event, row.invoice_no)
                              }
                            />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {row.name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">
                            {"#SSU-" + row.invoice_no}
                          </TableCell>
                          <TableCell align="left">{row.total}</TableCell>
                          <TableCell align="left">
                            {PaymentProvider(row.invoice_id).cash}
                          </TableCell>
                          <TableCell align="left">
                            {PaymentProvider(row.invoice_id).online}
                          </TableCell>
                          <TableCell align="left">{row.paid_amount}</TableCell>
                          <TableCell align="left">{row.full_name}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={inv.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            stateUpdate(userID.id, "1");
            handleCloseMenu();
          }}
        >
          <Iconify icon={"ooui:user-active"} sx={{ mr: 2 }} />
          Active
        </MenuItem>

        <MenuItem
          onClick={() => {
            stateUpdate(userID.id, "2");
            handleCloseMenu();
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify icon={"mdi:user-cancel"} sx={{ mr: 2 }} />
          Deactive
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenModal();
          }}
        >
          <Iconify icon={"bxs:edit"} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
      </Popover>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            borderRadius: 5,
            height: "90%",
            boxShadow: 24,
            padding:4
          }}
        >
          {/* New Pdf Generator start */}
          <PDFDownloadLink
        document={
          <PDFTYU/>
        } >
           {({ loading }) =>
                loading ? "Loading...": "Download PDF"
              }
        </PDFDownloadLink>
          <PDFViewer style={styles.viewer}>
            <PDFTYU/>
          </PDFViewer>
          {/* New Pdf Generator end */}
        </Box>
      </Modal>
    </>
  );
}

const styles2 = StyleSheet.create({
  table: {
    display: "table",
    width: 550,
    marginLeft: 0,
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom:10
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    borderStyle: "solid",
    //   borderBottomWidth: 1,
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    //   borderLeftWidth: 0,
    //   borderTopWidth: 0
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 7,
  },
  tableHeader: {
    color: "black",
    fontSize: 7,
  },
  pShell: {
    width: "20%",
  },
  qShell: {
    width: "10%",
  },
  wShell: {
    width: "10%",
  },
  sShell: {
    width: "5%",
  },
  bShell: {
    width: "15%",
  },
  AShell: {
    width: "20%",
  },
  FIsHELL: {
    width: "50%",
  },
  s54: {
    width: "54%",
  },
  s23: {
    width: "23%",
  },
});

const TablePdf = ({ inv, PaymentProvider }) => {
  return (
    <View style={styles2.table}>
      <View style={styles2.tableRow}>
        <View style={[styles2.tableCol, styles2.sShell]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>#</Text>
        </View>
        <View style={[styles2.tableCol, styles2.bShell]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>CUSTOMER</Text>
        </View>
        <View style={[styles2.tableCol, styles2.qShell]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>INVOICE</Text>
        </View>
        <View style={[styles2.tableCol, styles2.qShell]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>TOT AMT</Text>
        </View>
        <View style={[styles2.tableCol, styles2.qShell]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>CASH AMT</Text>
        </View>
        <View style={[styles2.tableCol, styles2.qShell]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>ONLINE</Text>
        </View>
        <View style={[styles2.tableCol, styles2.qShell]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>
            COLLECTION
          </Text>
        </View>
        <View style={[styles2.tableCol, styles2.qShell]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>SLTD</Text>
        </View>
        <View style={[styles2.tableCol, styles2.qShell]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>ORDER BY</Text>
        </View>
        <View style={[styles2.tableCol, styles2.qShell]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>REMARKS</Text>
        </View>
      </View>
      {inv.map((item, i) => (
        <View style={styles2.tableRow}>
          <View style={[styles2.tableCol, styles2.sShell]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}>{i + 1}</Text>
          </View>
          <View style={[styles2.tableCol, styles2.bShell]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}>
              {item.name}
            </Text>
          </View>
          <View style={[styles2.tableCol, styles2.qShell]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}>
              #SSU-{item.invoice_no}
            </Text>
          </View>
          <View style={[styles2.tableCol, styles2.qShell]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}>
              {item.total}
            </Text>
          </View>
          <View style={[styles2.tableCol, styles2.qShell]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}>
              {PaymentProvider(item.invoice_id).cash}
            </Text>
          </View>
          <View style={[styles2.tableCol, styles2.qShell]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}>
              {PaymentProvider(item.invoice_id).online}
            </Text>
          </View>
          <View style={[styles2.tableCol, styles2.qShell]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}>
              {item.paid_amount}
            </Text>
          </View>
          <View style={[styles2.tableCol, styles2.qShell]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}></Text>
          </View>
          <View style={[styles2.tableCol, styles2.qShell]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}>
              {item.full_name}
            </Text>
          </View>
          <View style={[styles2.tableCol, styles2.qShell]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}></Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const EmpTablePdf = ({ inv, employeeList }) => {
  const [array, setArray] = useState(() => {
    let ar = [];
    employeeList.forEach((item) => {
      let baby = inv.filter((i) => i.emp_id === item.id);
      if (baby.length > 0) {
        ar.push({ name: item.full_name, orders: baby.length });
      }
    });
    return ar;
  });
  return (
    <View style={styles2.table}>
      <View style={styles2.tableRow}>
        <View style={[styles2.tableCol, styles2.FIsHELL]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>
            INVOICE BY
          </Text>
        </View>
        <View style={[styles2.tableCol, styles2.FIsHELL]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>
            TOTAL ORDER
          </Text>
        </View>
      </View>
      {array.map((item, i) => (
        <View style={styles2.tableRow}>
          <View style={[styles2.tableCol, styles2.FIsHELL]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}>
              {item.name}
            </Text>
          </View>
          <View style={[styles2.tableCol, styles2.FIsHELL]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}>
              {item.orders}
            </Text>
          </View>
        </View>
      ))}
      <View style={styles2.tableRow}>
        <View style={[styles2.tableCol, styles2.FIsHELL]}>
          <Text style={[styles2.tableCell, styles2.tableCell]}>
            Total Quantity
          </Text>
        </View>
        <View style={[styles2.tableCol, styles2.FIsHELL]}>
          <Text style={[styles2.tableCell, styles2.tableCell]}>
            {inv.length}
          </Text>
        </View>
      </View>
    </View>
  );
};

const ProductOrderTable = ({ inv, allInvoiceProduct ,unit}) => {
  const [ array, setArray ] = useState(()=>{
    let filterCollectionAction = [];
    inv.forEach((item) => {
      let a = allInvoiceProduct.filter((i) => i.invoice_id === item.invoice_id);
      a.forEach((j) => {
        filterCollectionAction.push(j);
      });
    });
    
    let finalCollection = [];
    filterCollectionAction.forEach((item) => {
      if (finalCollection.length > 0) {
        let fu = finalCollection.filter(
          (i) => i.product.product_id === item.product_id
        );
    
        if (fu.length > 0) {
          let poss = finalCollection.indexOf(fu[0]);
    
          finalCollection[poss].qty =
            parseInt(finalCollection[poss].qty) + parseInt(item.qty);
        } else {
          finalCollection.push({ product: item, qty: item.qty });
        }
      } else {
        finalCollection.push({ product: item, qty: item.qty });
      }
    });
    return finalCollection;
  });

  const totalQuantity=(arr)=>{
    let total=0;
    arr.forEach((item)=>{
      total=total+parseInt(item.qty);
    })
    return total;
  }

  return (
    <View style={styles2.table}>
      <View style={styles2.tableRow}>
        <View style={[styles2.tableCol, styles2.s54]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>
            PRODUCT NAME
          </Text>
        </View>
        <View style={[styles2.tableCol, styles2.s23]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>QUANTITY</Text>
        </View>
        <View style={[styles2.tableCol, styles2.s23]}>
          <Text style={[styles2.tableCell, styles2.tableHeader]}>REMARK</Text>
        </View>
      </View>
      
        {array.map((item, i) => (
        <View style={styles2.tableRow}>
          <View style={[styles2.tableCol, styles2.s54]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}>
              {unit(item.product.weight)+" X "+item.product.product_name}
            </Text>
          </View>
          <View style={[styles2.tableCol, styles2.s23]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}>
              {item.qty}
            </Text>
          </View>
          <View style={[styles2.tableCol, styles2.s23]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}></Text>
          </View>
        </View>
      ))}

        <View style={styles2.tableRow}>
          <View style={[styles2.tableCol, styles2.s54]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}>
              Total Quantity
            </Text>
          </View>
          <View style={[styles2.tableCol, styles2.s23]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}>
            {totalQuantity(array)}
            </Text>
          </View>
          <View style={[styles2.tableCol, styles2.s23]}>
            <Text style={[styles2.tableCell, styles2.tableCell]}></Text>
          </View>
        </View>
      
    </View>
  );
};



