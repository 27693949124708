import { StyleSheet, Text, View,Font  } from '@react-pdf/renderer';
import React from 'react'


const styles = StyleSheet.create({
    table: { 
      display: "table", 
      width: 550, 
      borderStyle: "solid", 
      borderWidth: 0, 
      borderTopWidth:1,
      borderRightWidth: 0, 
      borderBottomWidth: 0 ,
      borderLeftWidth: 1,
      marginTop:0
    }, 
    tableRow: { 
      margin: "auto", 
      flexDirection: "row",
      borderStyle: "solid", 
      borderBottomWidth: 1, 
    }, 
    tableCol: {  
      borderStyle: "solid", 
      borderWidth: 0, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 ,
      borderRightWidth: 1
    }, 
    tableCell: { 
      margin: "auto", 
      marginTop: 5, 
      fontSize: 9 ,
      fontFamily: 'Open Sans',
      fontWeight:600
    },
    tableHeader:{
        color:"black",
        fontSize:10,
        fontFamily: 'Open Sans',
        fontWeight:600
    },
    pShell:{
        width: "30%"
    },
    qShell:{
        width:"10%"
    },
    wShell:{
        width:"10%"
    },
    AShell:{
        width:"20%"
    }
  });
  
  Font.register({
    family: 'Open Sans',
    fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
    ]
    });

const PDFTABLE = ({products,data,unitP,returns}) => {
    const price=100000.00;
    // let gstPrice=0;
    // products.forEach((item)=>{
    //   gstPrice=gstPrice + parseFloat(item.gst_per);
    // });
    let tweight=0;
  return (
    <View
     style={styles.table}> 
        <View style={styles.tableRow}> 
          <View style={[styles.tableCol,styles.pShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>PRODUCT</Text> 
          </View> 
          <View style={[styles.tableCol,styles.qShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>PCS</Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>WEIGHT</Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>GST %</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>PRICE</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>TOTAL</Text> 
          </View> 
        </View>
        {
            products.map((item,i)=>{
              tweight=tweight+parseFloat(item.weight) * parseInt(item.qty);
               
              return (
                <View style={styles.tableRow} key={i}> 
                <View style={[styles.tableCol,styles.pShell]}> 
                  <Text style={styles.tableCell}>{item.product_name}</Text> 
                  {/* <Text style={[styles.tableCell,{color:"gray"}]}>Delivery Date:{item.delivery_date}</Text>  */}
                </View> 
                <View style={[styles.tableCol,styles.qShell]}> 
                  <Text style={styles.tableCell}>{item.qty}</Text> 
                </View> 
                <View style={[styles.tableCol,styles.wShell]}>
                  <Text style={styles.tableCell}>{
                  unitP(parseFloat(item.weight) * parseInt(item.qty))}</Text> 
                </View>
                <View style={[styles.tableCol,styles.wShell]}>
                  <Text style={styles.tableCell}>{Math.round((parseFloat(item.gst_per).toFixed(2)*100)/parseFloat(item.price))}%</Text> 
                </View>
                <View style={[styles.tableCol,styles.AShell]}> 
                  <Text style={styles.tableCell}>{priceProvider(item.price)}</Text> 
                </View>
                <View style={[styles.tableCol,styles.AShell]}> 
                  <Text style={styles.tableCell}>{priceProvider(item.total_price)}</Text> 
                </View> 
              </View>
            )
          })
        }
        {
          returns.map((item,i)=>{
            tweight=tweight-parseFloat(item.weight) * parseInt(item.qty);
           return  (
            <View style={styles.tableRow} key={i}> 
                <View style={[styles.tableCol,styles.pShell]}> 
                  <Text style={styles.tableCell}>{item.product_name}</Text> 
                  <Text style={[styles.tableCell,{color:"gray"}]}>Return Date:{item.created_at}</Text> 
                </View> 
                <View style={[styles.tableCol,styles.qShell]}> 
                  <Text style={styles.tableCell}>{item.qty}</Text> 
                </View> 
                <View style={[styles.tableCol,styles.wShell]}>
                  <Text style={styles.tableCell}>{
                  unitP(parseFloat(item.weight) * parseInt(item.qty))}</Text> 
                </View>
                <View style={[styles.tableCol,styles.wShell]}>
                  <Text style={styles.tableCell}>{Math.round((parseFloat(item.gst_per).toFixed(2)*100)/parseFloat(item.price))}%</Text> 
                </View>
                <View style={[styles.tableCol,styles.AShell]}> 
                  <Text style={styles.tableCell}>{priceProvider(item.price)}</Text> 
                </View>
                <View style={[styles.tableCol,styles.AShell]}> 
                  <Text style={styles.tableCell}>{priceProvider(item.total_price)}</Text> 
                </View> 
              </View>
          )})
        }
        <View style={styles.tableRow}> 
          <View style={[styles.tableCol,styles.pShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View> 
          <View style={[styles.tableCol,styles.qShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>
            {unitP(tweight)}
              </Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>Total</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader,{color:"black"}]}>{priceProvider(data.sub_total)}</Text> 
          </View> 
        </View>
        {/* <View style={styles.tableRow}> 
          <View style={[styles.tableCol,styles.pShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View> 
          <View style={[styles.tableCol,styles.qShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>Discount</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>{priceProvider(data.discount)}</Text> 
          </View> 
        </View> */}
        {/* <View style={styles.tableRow}> 
          <View style={[styles.tableCol,styles.pShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View> 
          <View style={[styles.tableCol,styles.qShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View> 
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>GST</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}>{gstPrice.toFixed(2)}</Text> 
          </View> 
        </View> */}
        {/* <View style={styles.tableRow}> 
          <View style={[styles.tableCol,styles.pShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View> 
          <View style={[styles.tableCol,styles.qShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader,{color:"black"}]}>Grand Total</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader,{color:"black"}]}>{priceProvider(data.total)}</Text> 
          </View> 
        </View>
        <View style={styles.tableRow}> 
          <View style={[styles.tableCol,styles.pShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View> 
          <View style={[styles.tableCol,styles.qShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View> 
          <View style={[styles.tableCol,styles.wShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader]}></Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader,{color:"black"}]}>Last BF</Text> 
          </View>
          <View style={[styles.tableCol,styles.AShell]}> 
            <Text style={[styles.tableCell,styles.tableHeader,{color:"black"}]}>{priceProvider(data.invoice_last_bf)}</Text> 
          </View> 
        </View> */}
      </View>
  )
}

export default PDFTABLE

export const priceProvider=(price)=>{
    return ""+price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}
