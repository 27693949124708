import React from 'react'

import { styled } from '@mui/material'


const Container=styled("div")({

})
const Table=styled("table")({
    border:"1px solid black",
    borderCollapse:"collapse",
    width:"100%"
})
const THead=styled("th")({
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:18,
    fontWeight:"bold"
})

const TData=styled("td")({
    padding:0,
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:12,
    fontWeight:"bold"
})


const DailyReportTable = ({data}) => {
    let totalAmount=0;
    let Data=data.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
  return (
    <Container>
    <Table>
      <thead>
          <tr>
              <THead>#</THead>
              <THead>CUSTOMER</THead>
              <THead>INVOICE</THead>
              <THead>TOT AMT</THead>
              <THead>CASH AMT</THead>
              <THead>ONLINE</THead>
              <THead>SLTD</THead>
              <THead>ORDER BY</THead>
              <THead>REMARKS</THead>
          </tr>
      </thead>
      <tbody>
          {
              Data.map((item,i)=>{ 
                totalAmount=totalAmount+parseFloat(item.total);
                return (
                  <tr>
                      <TData style={{textAlign: "center"}}>{i+1}</TData>
                      <TData>{item.name}</TData>
                      <TData>#SSU-{item.invoice_no}</TData>
                      <TData>{item.total}</TData>
                      <TData></TData>
                      <TData></TData>
                      <TData></TData>
                      <TData style={{fontWeight: "bold"}}>{item.full_name}</TData>
                      <TData style={{width: 160}}></TData>
                  </tr>
              )}
              )
          }
          <tr>
                     <TData style={{textAlign: "center"}}></TData>
                      <TData></TData>
                      <TData></TData>
                      <TData>{totalAmount.toFixed(2)}</TData>
                      <TData></TData>
                      <TData></TData>
                      <TData></TData>
                      <TData style={{fontWeight: "bold"}}></TData>
                      <TData style={{width: 160}}></TData>
          </tr>
      </tbody>
    </Table>
  </Container>
  )
}

export default DailyReportTable
