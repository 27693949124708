import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import Swal from "sweetalert2";
import { useContext, useEffect, useRef, useState } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TextField,
  Box,
  Select,
  FormControl,
  InputLabel,
  Modal,
} from "@mui/material";
// components
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
// mock
import USERLIST from "../_mock/user";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import * as Yup from 'yup';
import { DownloadTableExcel } from "react-export-table-to-excel";
import { ExportToExcel } from "./ExportToExcel";
const phoneRegExp= /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const CustomerSchema= Yup.object().shape({
    mob: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10, 'Mobile No is 10 Digit Number').max(10, 'Mobile No is 10 Digit Number').required('Required')
  });

    function isValidGST(gstin) {
    // GST number must be 15 characters long.
    if (gstin.length !== 15) {
      return false;
    }
  
    // The first two characters must be a state code.
    const stateCode = gstin.substring(0, 2);
    if (!/^[0-9]{2}$/.test(stateCode)) {
      return false;
    }
  
    // The next 10 characters must be the PAN number of the taxpayer.
    const panNumber = gstin.substring(2, 12);
    if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(panNumber)) {
      return false;
    }
  
    // The 13th character must be any digit (1-9) or an alphabet.
    const checkDigit = gstin.charAt(12);
    if (!/^[1-9A-Z]{1}$/.test(checkDigit)) {
      return false;
    }
  
    // The 14th character must be Z by default.
    const defaultCharacter = gstin.charAt(13);
    if (defaultCharacter !== 'Z') {
      return false;
    }
  
    // The 15th character must be an alphabet or any digit.
    const lastCharacter = gstin.charAt(14);
    if (!/^[0-9A-Z]{1}$/.test(lastCharacter)) {
      return false;
    }
  
    // If all the conditions are met, the GST number is valid.
    return true;
  }

  function checksum(g){
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(g)
     if(regTest){
        let a=65,b=55,c=36,p=0;
        return Array['from'](g).reduce((i,j,k,g)=>{ 
           p=(p=(j.charCodeAt(0)<a?parseInt(j):j.charCodeAt(0)-b)*(k%2+1))>c?1+(p-c):p;
           return k<14?i+p:j==((c=(c-(i%c)))<10?c:String.fromCharCode(c+b));
        },0); 
    }
    return regTest
}

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "mob", label: "Mobile No", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
  { id: "gst", label: "GST", alignRight: false },
  { id: "state", label: "State", alignRight: false },
  { id: "dist", label: "Dist", alignRight: false },
  { id: "created_by", label: "Created By", alignRight: false },
  { id: "balance", label: "Account Balance", alignRight: false },


    // { id: 'status', label: 'Status', alignRight: false },
    { id: '', label:'Action' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.c_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomerPage({ context }) {

  const tableRef=useRef();

  const [add, openAdd] = useState(false);
  const [open, setOpen] = useState(null);
  const {invoices,invoicesAPI, states,updateCustomerAPI,addCustomerAPI,getCustomer,customers,products,units, getProducts,deleteInventoryAPI, user, unitProvider ,addInventoryAPI,inventory,getInventory} =
    useContext(context);
  const navigate = useNavigate();
  const [dists,setDists]=useState([]);
  
  useEffect(() => {
        getCustomer();
        getInventory();
        getProducts((v)=>{})
        invoicesAPI()
  }, []);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [editUser, setEditUser] = useState({});

  

  const handleOpenMenu = (event, data) => {
    setEditUser(data);
    const obj=states.filter((item)=>item.state == data.c_state )[0];
    setDists(obj.districts);
 
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setEditUser({});
    setDists([]);
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = customers.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
 
  const handleBalance=(row)=>{
    let Total=0;
    const array=invoices.filter((item)=>item.customer_id == row.c_id).forEach((i)=>{
        Total=Total + (parseFloat(i.total)-parseFloat(i.paid_amount));
      }
    );

      return Math.round(Total).toFixed(2);
  }
 

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - customers.length) : 0;

  const filteredUsers = applySortFilter(
    customers,
    getComparator(order, orderBy),
    filterName
  );



        const [openModal, setOpenModal] = useState(false);
        const handleOpenModal = () => setOpenModal(true);
        const handleCloseModal = () => setOpenModal(false);

  const isNotFound = !filteredUsers.length && !!filterName;
  let currentDate = new Date().toJSON().slice(0, 10);


  const handleExportData=(data)=>{
     let array=[];
     data.map((item,i)=>{
       array.push({
        slno:(i+1),
        name:item.c_name,
        email:item.c_email,
        phone:item.c_phone,
        gst:item.gst,
        address:item.address_line1,
        town:item.town,
        state:item.c_state,
        account_balance:handleBalance(item)
       });
     })
     return array;
  }

  return (
    <>
      <Helmet>
        <title> Customers | Shree Smile </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Customers
          </Typography>
          {/* <DownloadTableExcel
                    filename="Customer"
                    sheet="customer"
                    currentTableRef={tableRef.current}
                >

                   <button> Export excel </button>

                </DownloadTableExcel> */}
                <ExportToExcel apiData={handleExportData(customers)} fileName="Customer List" />
          <Button
            variant="contained"
            startIcon={<Iconify icon={add ? "ic:baseline-close" : "mdi:add"} />}
            onClick={() => {
              openAdd(!add);
            }}
          >
            {add ? "Close" : "Add Customer"}
          </Button>
        </Stack>
        {add ? (
          <Paper elevation={2} sx={{ padding: 3, marginBottom: 5 }}>
            <Formik
              initialValues={{name:"",mob:"",address:"",gst:"",state:"",dist:"",emp_id:user.id }}
              validationSchema={CustomerSchema}
              onSubmit={(values, { setSubmitting }) => {
                if(values.gst != ""){
                    if(!isValidGST(values.gst)){
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Invalid GST Number",
                            showConfirmButton: false,
                            timer: 1500,
                          }); 
                    }
                    else{
                        addCustomerAPI(values,()=>{
                            getCustomer(); 
                        })
                        openAdd(false);
                    }
                }else{
                addCustomerAPI(values,()=>{
                    getCustomer(); 
                })
                openAdd(false);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <>
                  <Typography variant="h6" gutterBottom>
                    Customer Details
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      padding: 5,
                      justifyContent:"space-evenly",
                      flexWrap: "wrap",
                      marginBottom: 5,
                      marginTop: 5,
                      gap: 5,
                    }}
                  >
                    <TextField 
                     name="name"
                     type="text" 
                     label="Name"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.name}
                     required
                     />
                     <TextField 
                     name="mob"
                     type="text" 
                     label="Mobile No"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.mob}
                     required
                     />
                      <TextField 
                     name="address"
                     type="text" 
                     label="Address"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.address}
                     required
                     />
                      <TextField 
                     name="gst"
                     type="text" 
                     label="GST"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.gst}
                     />
                    <FormControl sx={{ minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="State"
                        name="state"
                        onChange={(v)=>{handleChange(v);
                        const obj=states.filter((item)=>item.state == v.target.value )[0];
                        setDists(obj.districts);
                        }}
                        onBlur={handleBlur}
                        value={values.state}
                        required
                      >
                        {states.map((item) => (
                          <MenuItem value={item.state}>
                            {item.state}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-label2">
                        Dist
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label2"
                        id="demo-simple-select"
                        label="Dist"
                        name="dist"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.dist}
                        required
                      >
                        {dists.map((item) => (
                          <MenuItem value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Stack spacing={1}>
                 <Typography> {errors.mob && touched.mob && errors.mob}</Typography>
                  </Stack>
                  <LoadingButton size="small" type="submit" variant="contained" onClick={handleSubmit} >
                    Save
                  </LoadingButton>
                </>
              )}
            </Formik>
          </Paper>
        ) : (
          <></>
        )}

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table ref={tableRef}>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={customers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { c_id ,c_name,c_phone,address_line1,town,c_state,gst,full_name,user_id,employee_id} = row;
                      const selectedUser = selected.indexOf(c_name) !== -1;
                      return (
                        <TableRow
                          hover
                          key={c_id }
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedUser}
                              onChange={(event) => handleClick(event, c_name)}
                            />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {c_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{c_phone}</TableCell>

                          <TableCell align="left">{address_line1}</TableCell>

                          <TableCell align="left">{gst}</TableCell>
                          <TableCell align="left">{c_state}</TableCell>
                          <TableCell align="left">{town}</TableCell>
                          <TableCell align="left">{employee_id+"/"+full_name}</TableCell>
                          <TableCell align="left">{handleBalance(row)}</TableCell>

                          {/* <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(s_msg)}</Label>
                        </TableCell> */}

                          <TableCell align="center">
                          <IconButton size="large" color="inherit" onClick={(e)=>handleOpenMenu(e,row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={customers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleOpenModal()
          }}
        >
          <Iconify icon={"bxs:edit"} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {/* <MenuItem
          onClick={() => {
            // deleteInventoryAPI(editUser.inventory_id,()=>{
            //     getInventory();
            //     handleCloseMenu();
            // })
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify icon={"ic:baseline-delete"} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
      </Popover>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius:5,

  boxShadow: 24,
  p: 4,
}} >
         
         <Formik
              initialValues={{name:editUser.c_name,mob:editUser.c_phone,address:editUser.address_line1,gst:editUser.gst,state:editUser.c_state,dist:editUser.town,id:editUser.c_id }}
              validationSchema={CustomerSchema}
              onSubmit={(values, { setSubmitting }) => {
                if(values.gst != ""){
                    if(!isValidGST(values.gst)){
                        handleCloseModal();
                        handleCloseMenu();
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Invalid GST Number",
                            showConfirmButton: false,
                            timer: 1500,
                          }); 
                    }
                    else{
                        updateCustomerAPI(values,()=>{
                            getCustomer(); 
                            handleCloseModal();
                        })
                        handleCloseMenu();
                    }
                }else{
                updateCustomerAPI(values,()=>{
                    getCustomer();
                    handleCloseModal();
                })
                handleCloseMenu();
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <>
                  <Typography variant="h6" gutterBottom>
                    Customer Details
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      padding: 5,
                      justifyContent:"space-evenly",
                      flexWrap: "wrap",
                      marginBottom: 5,
                      marginTop: 5,
                      gap: 5,
                    }}
                  >
                    <TextField 
                     name="name"
                     type="text" 
                     label="Name"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.name}
                     required
                     />
                     <TextField 
                     name="mob"
                     type="text" 
                     label="Mobile No"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.mob}
                     required
                     />
                      <TextField 
                     name="address"
                     type="text" 
                     label="Address"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.address}
                     required
                     />
                      <TextField 
                     name="gst"
                     type="text" 
                     label="GST"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.gst}
                     />
                    <FormControl sx={{ minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="State"
                        name="state"
                        onChange={(v)=>{handleChange(v);
                        const obj=states.filter((item)=>item.state == v.target.value )[0];
                        setDists(obj.districts);
                        }}
                        onBlur={handleBlur}
                        value={values.state}
                        required
                      >
                        {states.map((item) => (
                          <MenuItem value={item.state}>
                            {item.state}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-label2">
                        Dist
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label2"
                        id="demo-simple-select"
                        label="Dist"
                        name="dist"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.dist}
                        required
                      >
                        {dists.map((item) => (
                          <MenuItem value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Stack spacing={1}>
                 <Typography> {errors.mob && touched.mob && errors.mob}</Typography>
                  </Stack>
                 <LoadingButton size="small" type="submit" variant="contained" onClick={handleSubmit} >
                    Update
                  </LoadingButton>
                  <LoadingButton size="small" type="submit" variant="contained" onClick={handleCloseModal} style={{marginLeft:10}} >
                    Close
                  </LoadingButton>
                </>
              )}
            </Formik>
        </Box>
      </Modal>  

    </>
  );
}
