import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useContext, useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  TextField,
  InputAdornment,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'employee_id', label: 'Employee ID', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'password', label: 'Password', alignRight: false },
  { id: 'mobile', label: 'Mobile', alignRight: false },
  { id: 'addhar', label: 'Aadhara', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '', label:'Action' },
];


const phoneRegExp= /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const SignUpSchema= Yup.object().shape({
    mob: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10, 'Mobile No is 10 Digit Number').max(10, 'Mobile No is 10 Digit Number').required('Required'),
    aadhar:Yup.string().matches(phoneRegExp, 'Addhar number is not valid').min(12, 'Addhar is 12 Digit Number').max(12, 'Addhar is 12 Digit Number').required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  })

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage({context}) {
  const [open, setOpen] = useState(null);
  const { employeeList,getEmployee,user,stateUpdate,getInventory,updateUserAPI }=useContext(context);
  const navigate=useNavigate();
  useEffect(()=>{
    if (user.user_type != 1) {
      navigate("/app");
    } else {
    getEmployee();
    getInventory();
    }
  },[])
  const updateList= async ()=>{
    await getEmployee();
  }
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [userID,setuserId]=useState(0);

  const handleOpenMenu = (event,data) => {
    setuserId(data);
    setOpen(event.currentTarget);
    
  };

  const handleCloseMenu = () => {
    setuserId(0);
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = employeeList.map((n) => n.full_name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
 
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - employeeList.length) : 0;

  const filteredUsers = applySortFilter(employeeList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <Helmet>
        <title> User | Shree Smile </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Employee 
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="mdi:refresh" />} onClick={updateList}>
           Refresh
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={employeeList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id,full_name,email,password,mobile_no,address,adhara_card,user_id,login_status,expired_status,employee_id } = row;
                    const selectedUser = selected.indexOf(full_name) !== -1;
                    let status="";
                    let s_msg="";
                    switch(login_status){
                      case "0": status="banned";s_msg="Logout";
                        break;
                      case "1": status="success";s_msg="Login";
                        break;
                     default:
                      status="";s_msg="";
                    }
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, full_name)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {full_name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{employee_id}</TableCell>
                        
                        <TableCell align="left">{email}</TableCell>

                        <TableCell align="left">{password}</TableCell>
                        
                        <TableCell align="left">{mobile_no}</TableCell>
                        
                        <TableCell align="left">{adhara_card}</TableCell>
                        
                        <TableCell align="left">{address}</TableCell>

                        <TableCell align="left">{expired_status == 1 ? 'Yes' : 'No'}</TableCell>

                        <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(s_msg)}</Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e)=>handleOpenMenu(e,row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25,50,100]}
            component="div"
            count={employeeList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={()=>{
          stateUpdate(userID.id,"1");
          handleCloseMenu();
        }}>
          <Iconify icon={'ooui:user-active'} sx={{ mr: 2 }} />
          Active
        </MenuItem>

        <MenuItem
        onClick={()=>{
          stateUpdate(userID.id,"2");
          handleCloseMenu();
        }}
        sx={{ color: 'error.main' }}>
          <Iconify icon={'mdi:user-cancel'} sx={{ mr: 2 }} />
          Deactive
        </MenuItem>
        <MenuItem
        onClick={()=>{
          handleOpenModal()
        }}
       >
          <Iconify icon={'bxs:edit'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
      </Popover>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius:5,

  boxShadow: 24,
  p: 4,
}} >
  <Formik
        initialValues={{ id:userID.id,fname:userID.full_name,address:userID.address,mob:userID.mobile_no,aadhar:userID.adhara_card, email:userID.email, password: userID.password }}
        validationSchema={SignUpSchema}
        onSubmit={(values, { setSubmitting }) => {
          updateUserAPI(values,()=>{updateList();
            handleCloseMenu();
          });
          handleCloseModal();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (<>
            <Stack spacing={3}>
               <TextField
              name="fname" 
              label="Name" 
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fname}
              required
              />  
               <TextField 
              name="address" 
              label="Address" 
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              required
              />
               <TextField 
              name="mob" 
              label="Mobile No" 
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mob}
              required
              />
              {errors.mob && touched.mob && errors.mob}
               <TextField 
              name="aadhar" 
              label="Aadhar" 
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.aadhar}
              required
              />
              {errors.aadhar && touched.aadhar && errors.aadhar}
              <TextField 
              name="email" 
              label="Email address" 
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              />
              {errors.email && touched.email && errors.email}
              <TextField
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                required
              />
               {errors.password &&
                            touched.password &&
                            errors.password}
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              {/* <Checkbox name="remember" label="Remember me" />
              <Link variant="subtitle2" underline="hover">
                Forgot password?
              </Link> */}
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
             Update
            </LoadingButton>
            </>
        )}
      </Formik>

  </Box>
  </Modal>

    </>
  );
}
