import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppContextProvider from "./context/AppContextProvider";
import Main from "./Main";
import { Context } from "./context/AppContextProvider";
import LoginScreen from "./screens/LoginScreen";
import LoadingScreen from "./screens/LoadingScreen";
import { useContext } from "react";
import { HelmetProvider } from "react-helmet-async";
import ThemeProvider from './theme'
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import AppRoutes from "./routes";
function App() {
  return (
    <AppContextProvider>
    <HelmetProvider>
     <AppRouteProvider/>
     </HelmetProvider>
    </AppContextProvider>
  );
}

export default App;


const AppRouteProvider=()=>{
  const { isLogin,isLoading }=useContext(Context);
  return ( 
  <BrowserRouter>
     <ThemeProvider>
      {/* <ScrollToTop> */}
      <StyledChart />
      {isLoading && <LoadingScreen />}
       <AppRoutes/>
    {/* </ScrollToTop> */}
    </ThemeProvider>
  </BrowserRouter>
    
  );
}


