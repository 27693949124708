import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import Swal from "sweetalert2";
import { useContext, useEffect, useRef, useState } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TextField,
  Box,
  Select,
  FormControl,
  InputLabel,
  Modal,
  Autocomplete,
  createFilterOptions
} from "@mui/material";
// components
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
// mock
import USERLIST from "../_mock/user";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const CustomerSchema = Yup.object().shape({
  // name: Yup.string().required('Required'),
  // saddress: Yup.string().required('Required'),
  // baddress: Yup.string().required('Required'),
});

function checksum(g) {
  let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(g);
  if (regTest) {
    let a = 65,
      b = 55,
      c = 36,
      p = 0;
    return Array["from"](g).reduce((i, j, k, g) => {
      p =
        (p =
          (j.charCodeAt(0) < a ? parseInt(j) : j.charCodeAt(0) - b) *
          ((k % 2) + 1)) > c
          ? 1 + (p - c)
          : p;
      return k < 14
        ? i + p
        : j == ((c = c - (i % c)) < 10 ? c : String.fromCharCode(c + b));
    }, 0);
  }
  return regTest;
}

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Product Name", alignRight: false },
  { id: "qty", label: "Quantity", alignRight: false },
  { id: "unit", label: "Unit", alignRight: false },
  { id: "total_weight", label: "Total Weight", alignRight: false },
  { id: "gst_amount", label: "GST Amount", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
  { id: "total", label: "Total", alignRight: false },
  { id: "action", label: "Action" },
];

const filterOPtions=createFilterOptions({
  ignoreCase:true,
  mathFrom:"start",
  limit:10
})
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
function getDifference(array1, array2) {
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1.id === object2.id;
    });
  });
}

export default function InvoicePage({ context }) {
  const [add, openAdd] = useState(false);
  const [open, setOpen] = useState(null);
  const [iProducts, setIProducts] = useState([]);
  const {
    invoicesAPI,
    invoices,
    configCatcher,
    gstPer,
    addInvoiceAPI,
    states,
    updateCustomerAPI,
    addCustomerAPI,
    getCustomer,
    customers,
    products,
    units,
    getProducts,
    deleteInventoryAPI,
    user,
    unitProvider,
    addInventoryAPI,
    inventory,
    getInventory,
  } = useContext(context);
  const navigate = useNavigate();
  const [dists, setDists] = useState([]);
  const [ProductState, setProdcutSate] = useState(products);
  const [Sub_total, setSubTotal] = useState(0);
  const [Gst, setGST] = useState(0);
  const [Grand_total, setGrandTotal] = useState(0);
  const [Discount, setDiscount] = useState(0);
  const handleProduct = (p = products, callback) => {
    try {
      const iProduct = JSON.parse(localStorage.getItem("iProducts")) || "";
      if (iProduct != "" && iProduct.length > 0) {
        setIProducts(iProduct);
        setProdcutSate(
          getDifference(
            p.filter((item) => item.quantity > 0),
            iProduct
          )
        );
        callback(iProduct);
      } else {
        setProdcutSate(p.filter((item) => item.quantity > 0));
        setSubTotal(0);
        setGST(0);
        setGrandTotal(0);
        setIProducts([]);
      }
    } catch (e) {}
  };
  useEffect(() => {
    configCatcher();
    getCustomer();
    getInventory();
    getProducts((v) => {
      handleProduct(v, (arraY) => {
        let total = 0;
        arraY.map((item) => {
          total = total + parseFloat(item.price) * parseInt(item.qty);
        });
        setSubTotal(total);
        total = total - Discount;
        const gst = (total / 100) * gstPer;
        setGST(gst);
        setGrandTotal(total + gst);
      });
    });
    invoicesAPI();
  }, []);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [editUser, setEditUser] = useState({});

  const BFCalculater = (inv) => {
    let bf = 0;
    inv.map((item) => {
      bf = bf + (parseFloat(item.total)-parseFloat(item.paid_amount));
    });
    return bf.toFixed(2);
  };

  const handleOpenMenu = (event, data) => {
    setEditUser(data);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setEditUser({});
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = iProducts.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - iProducts.length) : 0;

  const filteredUsers = applySortFilter(
    iProducts,
    getComparator(order, orderBy),
    filterName
  );

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const isNotFound = !filteredUsers.length && !!filterName;
  let currentDate = new Date().toJSON().slice(0, 10);
  const [customerID, setcustomerID] = useState(0);
  const [prodcutID, setproductID] = useState(0);
  let ACustomer = [];
  customers.map((item) => {
    ACustomer.push({ label: item.c_name+"/"+item.address_line1+"/"+item.town, value: item.c_id });
  });
  ACustomer=ACustomer.sort(function (a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
  let AProductState=[];
  ProductState.map((item)=>{
    AProductState.push({label:item.name + "/-" + unitProvider(item.unit),value:item.id})
  })

  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  const [valueProduct,setValueProduct]=useState(()=>{
    // if(AProductState.length > 0){
    //   return AProductState[0];
    // }
    return [];
  });

  const autoC = useRef(null);


  return (
    <>
      <Helmet>
        <title> Invoice | Shree Smile </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Invoice Create
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon={"mdi:add"} />}
            onClick={() => {
              //   openAdd(!add);
            }}
          >
            Add Invoice
          </Button>
        </Stack>

        <Formik
          initialValues={{
            bf: "",
            name: "",
            date: currentDate,
            due_date: currentDate,
            baddress: "",
            saddress: "",
            product: "",
            price: 0,
            qty: 1,
            delivery_date: currentDate,
            emp_id: user.id,
            gst: "",
            sub_total: "",
            grand_total: "",
            discount: "",
          }}
          // validationSchema={CustomerSchema}
          onSubmit={(values, { resetForm }) => {
            if (customerID != 0) {
              if (Discount != "" || Discount == 0) {
                if (iProducts.length > 0) {
                  // Swal.fire({
                  //   position: "center",
                  //   icon: "warning",
                  //   title: "Warning",
                  //   text: `Please be advised that the current action involves a final submission. Once submitted, the content will be considered as a permanent record and will not be editable or deletable in the future. Take this opportunity to thoroughly review all information, ensuring its accuracy and completeness before proceeding.

                  //           We understand the importance of accurate and complete submissions, as they often serve as official records or representations. Kindly double-check all details to avoid any unintended errors or omissions. Should you have any doubts or concerns, please seek assistance prior to submitting.
                            
                  //           By proceeding with this submission, you acknowledge that the content will be considered final and unchangeable. Please exercise caution and diligence during this process.
                            
                  //           Thank you for your understanding and cooperation.`,
                  //   showConfirmButton: true,
                  // }).then((result) => {
                  //   if (result.isConfirmed) {
                      const RequestOBJ = {
                        name: customerID,
                        billing_date: values.date,
                        billing_address: values.baddress,
                        shipping_address: values.saddress,
                        product_invoice: iProducts,
                        sub_total: Sub_total,
                        discount: Discount,
                        gst_amount: Gst,
                        gst: gstPer,
                        due_date: values.due_date,
                        grand_total: Grand_total,
                        emp_id: values.emp_id,
                        invoice_last_bf: values.bf,
                      };
                      console.log("RequestOBJ:", RequestOBJ);
                      addInvoiceAPI(RequestOBJ, () => {
                        localStorage.setItem("iProducts", JSON.stringify([]));
                        setIProducts([]);
                        setSubTotal(0);
                        setGST(0);
                        setGrandTotal(0);
                        resetForm({
                          name: "",
                          date: currentDate,
                          due_date: currentDate,
                          baddress: "",
                          saddress: "",
                          product: "",
                          qty: "",
                          delivery_date: currentDate,
                          emp_id: user.id,
                          gst: "",
                          sub_total: "",
                          grand_total: "",
                          discount: "",
                        });

                        getProducts((v) => {
                          handleProduct(v, (arraY) => {
                            let total = 0;
                            arraY.map((item) => {
                              total = total + parseFloat(item.price) * parseInt(item.qty);
                            });
                            setSubTotal(total);
                            total = total - Discount;
                            const gst = (total / 100) * gstPer;
                            setGST(gst);
                            setGrandTotal(total + gst);
                          });
                        });

                      });
                  //   }
                  // });
                } else {
                  Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Please Add Product To Invoice",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              } else {
                alert("Discount Didnot Empty!!");
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm,
            /* and other goodies */
          }) => (
            <>
              <Paper elevation={2} sx={{ padding: 3, marginBottom: 5 }}>
                <Typography variant="h6" gutterBottom>
                  Customer Section
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    padding: 5,
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                    marginBottom: 5,
                    marginTop: 5,
                    gap: 5,
                  }}
                >
                  {/* <FormControl sx={{ minWidth: 160 }} required>
                      <InputLabel id="demo-simple-select-label-name">
                        Customer Name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-name"
                        id="demo-simple-select"
                        label="Customer Name"
                        name="name"
                        onChange={(v)=>{handleChange(v);
                         let value=v.target.value;
                          const inv=invoices.filter((item) => {
                            let invoiceDate = new Date(item.created_at);
                            let Date89=new Date(values.date);
                            return (
                              item.customer_id === value &&
                              invoiceDate <= Date89 &&
                              item.payment_paid === "0"
                            );
                          });
                          const bf=BFCalculater(inv);
                          setFieldValue('bf',bf);
                        }}
                        onBlur={handleBlur}
                        value={values.name}
                        required
                      >
                        {customers.map((item) => (
                          <MenuItem value={item.c_id}>
                            {item.c_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                  <Autocomplete
                    disablePortal
                    // filterOptions={filterOPtions}
                    id="combo-box-demo"
                    options={ACustomer}
                    name="name"
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        let value = newValue.value;
                        setcustomerID(newValue.value);
                        const inv = invoices.filter((item) => {
                          let invoiceDate = new Date(item.created_at);
                          let Date9 = new Date(values.date);
                          const Date89=addDays(Date9, 1);
                          
                          return (
                            item.customer_id === value &&
                            invoiceDate < Date89 &&
                            item.payment_paid === "0"
                          );
                        });
                        const bf = BFCalculater(inv);
                        setFieldValue("bf", bf);
                      } else {
                        setFieldValue("bf", "");
                        setcustomerID(0);
                      }
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Customer Name" />
                    )}
                  />
                  <TextField
                    // sx={{width:10}}
                    name="date"
                    type="date"
                    label="Date"
                    onChange={(v) => {
                      handleChange(v);
                      let value = v.target.value;
                      const inv = invoices.filter((item) => {
                        let invoiceDate = new Date(item.created_at);
                        let Date9 = new Date(value);
                        const Date89=addDays(Date9, 1);
                        return (
                          item.customer_id === customerID &&
                          invoiceDate < Date89 &&
                          item.payment_paid === "0"
                        );
                      });
                      const bf = BFCalculater(inv);
                      setFieldValue("bf", bf);
                    }}
                    onBlur={handleBlur}
                    value={values.date}
                    required
                  />
                  {/* Owner wants to remove */}
                  <TextField
                    name="bf"
                    type="text"
                    label="Customer Last BF"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bf}
                    disabled
                  />
                  {/* <TextField 
                     name="baddress"
                     type="text" 
                     label="Billing Address"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.baddress}
                     required
                     /> */}
                  {/* <TextField 
                     name="saddress"
                     type="text" 
                     label="Shipping Address"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.saddress}
                     required
                     /> */}
                </Box>
                <Typography variant="h6" gutterBottom>
                  Product Add Section
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    padding: 5,
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                    marginBottom: 5,
                    marginTop: 5,
                    gap: 5,
                  }}
                >
                  {/* <FormControl sx={{ minWidth: 160 }}>
                    <InputLabel id="demo-simple-select-label">
                      Product
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Product"
                      name="product"
                      onChange={(v) => {
                        handleChange(v);
                        const value = v.target.value;
                        const product = products.filter(
                          (item) => item.id === value
                        );
                        setFieldValue("price", product[0].actual_price);
                      }}
                      onBlur={handleBlur}
                      value={values.product}
                    >
                      {ProductState.map((item) => (
                        <MenuItem value={item.id}>
                          {item.name + "/-" + unitProvider(item.unit)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Autocomplete
      disablePortal
      id="combo-box-demo45"
      ref={autoC}
      value={valueProduct}
      options={AProductState}
      onChange={(e,newValue)=>{
        setValueProduct(newValue);
        if(newValue!=null){
        const value = newValue.value;
        setproductID(value);
        const product = products.filter(
          (item) => item.id === value
        );
         setFieldValue("price", product[0].sell_price);
        }else{
         setFieldValue("price", ''); 

         setproductID(0);
        }
      }}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Product" />}
    />
                  <TextField
                    name="price"
                    label="Price"
                    type="number"
                    onChange={(v) => {
                      if (prodcutID != 0) {
                        const obj = products.filter(
                          (item) => item.id === prodcutID
                        );
                        if (v.target.value < 0) {
                          alert("Negative  Value Not Allow!!!");
                        } else {
                          handleChange(v);
                        }
                      } else {
                        alert("Please select product First!!!");
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.price}
                  />
                  <Box sx={{gap:3}}>
                  <Button variant="outlined" 
                  onClick={()=>{
                    if(values.qty > 1){
                      let v= parseInt(values.qty) - 1;
                      if (prodcutID != 0) {
                        const obj = products.filter(
                          (item) => item.id === prodcutID
                        );
                        if (
                          parseInt(v) > parseInt(obj[0].quantity)
                        ) {
                          alert(
                            `That much Stock of ${obj[0].name} not Avilable!!!`
                          );
                        } else if (v < 0) {
                          alert("Negative  Value Not Allow!!!");
                        } else {
                          setFieldValue('qty',v);
                        }
                      } else {
                        alert("Please select product First!!!");
                      }
                    }
                  }}>-</Button>
                  <TextField
                    sx={{width:50}}
                    // disabled
                    name="qty"
                    label="Quantity"
                    type="number"
                    onChange={(v) => {
                      if (prodcutID != 0) {
                        const obj = products.filter(
                          (item) => item.id === prodcutID
                        );
                        if (
                          parseInt(v.target.value) > parseInt(obj[0].quantity)
                        ) {
                          alert(
                            `That much Stock of ${obj[0].name} not Avilable!!!`
                          );
                        } else if (v.target.value < 0) {
                          alert("Negative  Value Not Allow!!!");
                        } else {
                          handleChange(v);
                        }
                      } else {
                        alert("Please select product First!!!");
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.qty}
                  />
                  <Button variant="outlined"
                   onClick={()=>{
                    // if(values.qty > 1){
                      let v= parseInt(values.qty) + 1;
                      if (prodcutID != 0) {
                        const obj = products.filter(
                          (item) => item.id === prodcutID
                        );
                        if (
                          parseInt(v) > parseInt(obj[0].quantity)
                        ) {
                          alert(
                            `That much Stock of ${obj[0].name} not Avilable!!!`
                          );
                        } else if (v < 0) {
                          alert("Negative  Value Not Allow!!!");
                        } else {
                          setFieldValue('qty',v);
                        }
                      } else {
                        alert("Please select product First!!!");
                      }
                    // }
                  }}
                  >+</Button>
                  </Box>
                  
                  {/* Owner wants to remove */}
                  {/* <TextField 
                     name="delivery_date"
                     type="date" 
                     label="Delivery Date"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.delivery_date}
                     /> */}
                </Box>
                <Stack padding={3}>
                  <LoadingButton
                    size="small"
                    type="button"
                    variant="contained"
                    onClick={() => {
                      if (
                        prodcutID != 0 &&
                        parseInt(values.qty) > 0 &&
                        values.delivery_date != "" &&
                        values.price != ""
                      ) 
                      {
                        const obj = products.filter(
                          (item) => item.id === prodcutID
                        );
                        const gwst =
                          (parseFloat(values.price) / 100) *
                          parseInt(obj[0].gst_per);
                        const pr = parseFloat(values.price);
                        const Gst =
                          parseFloat(obj[0].sell_price) -
                          parseFloat(obj[0].actual_price);
                        const setOBJ = {
                          id: obj[0].id,
                          name: obj[0].name,
                          actual_qty: obj[0].quantity,
                          qty: values.qty,
                          gst_amount: gwst.toFixed(2),
                          delivery_date: values.delivery_date,
                          unit: obj[0].unit,
                          price: pr.toFixed(2),
                        };
                        let arraY = iProducts;
                        arraY.push(setOBJ);
                        localStorage.setItem(
                          "iProducts",
                          JSON.stringify(arraY)
                        );
                        setIProducts(arraY);
                        setFieldValue("product", "");
                        setFieldValue("qty", "1");
                        setFieldValue("price", "");
                        setproductID(0);
                        setValueProduct([]);
                        handleProduct(products, (arra) => {
                          let total = 0;
                          arraY.map((item) => {
                            total =
                              total +
                              parseFloat(item.price) * parseInt(item.qty);
                          });
                          setSubTotal(total.toFixed(2));
                          total = total - Discount;
                          const gst = (total / 100) * gstPer;
                          setGST(gst);
                          setGrandTotal((total + gst).toFixed(2));
                        });
                      } else {
                        alert("Product,Price & Quantity Required ");
                      }
                    }}
                  >
                    Add Product
                  </LoadingButton>
                </Stack>
                <Stack spacing={1}>
                  {/* <Typography> {errors.mob && touched.mob && errors.mob}</Typography> */}
                </Stack>
              </Paper>

              <Card>
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={iProducts.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredUsers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            const selectedUser =
                              selected.indexOf(row.name) !== -1;
                            return (
                              <TableRow
                                hover
                                key={row.id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={selectedUser}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={selectedUser}
                                    onChange={(event) =>
                                      handleClick(event, row.name)
                                    }
                                  />
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    {/* <Avatar alt={name} src={avatarUrl} /> */}
                                    <Typography variant="subtitle2" noWrap>
                                      {row.name}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{row.qty}</TableCell>

                                <TableCell align="left">
                                  {unitProvider(row.unit)}
                                </TableCell>
                                <TableCell align="left">
                                  {unitProvider(
                                    parseFloat(row.unit) * parseInt(row.qty)
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {row.gst_amount}
                                </TableCell>
                                <TableCell align="left">
                                  {parseFloat(row.price).toFixed(2)}
                                </TableCell>

                                <TableCell align="left">
                                  {(
                                    parseFloat(row.price) * parseInt(row.qty)
                                  ).toFixed(2)}
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    size="large"
                                    color="inherit"
                                    onClick={(e) => handleOpenMenu(e, row)}
                                  >
                                    <Iconify icon={"eva:more-vertical-fill"} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              <Paper
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Not found
                                </Typography>

                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using
                                  complete words.
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={iProducts.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
              <Paper elevation={2} sx={{ padding: 3, marginTop: 5 }}>
                <Box
                  style={{
                    display: "flex",
                    padding: 5,
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                    marginBottom: 5,
                    marginTop: 5,
                    gap: 5,
                  }}
                >
                  <TextField
                    disabled
                    name="sub_total"
                    type="text"
                    label="Sub Total"
                    onChange={(v) => {
                      setSubTotal(v.target.value);
                    }}
                    onBlur={handleBlur}
                    value={Sub_total}
                  />
                  <TextField
                    name="discount"
                    type="number"
                    label="Discount"
                    onChange={(v) => {
                      if (isNaN(v.target.value)) {
                        alert("Discount Price must be a number");
                      } else if (
                        parseFloat(v.target.value) < 0 ||
                        parseFloat(v.target.value) > Sub_total
                      ) {
                        alert("Discount Price must be >= zero");
                      } else {
                        setDiscount(v.target.value);
                        const bb = Sub_total - parseFloat(v.target.value);
                        setGST((bb / 100) * gstPer);
                        setGrandTotal(bb + (bb / 100) * gstPer);
                      }
                    }}
                    onBlur={handleBlur}
                    value={Discount}
                  />
                  {/* <TextField 
                     disabled
                     name="gst"
                     type="text" 
                     label={"GST("+gstPer+"%)"}
                     onChange={(v)=>{setGST(v.target.value)}}
                     onBlur={handleBlur}
                     value={Gst}
                     /> */}
                  <TextField
                    disabled
                    name="grand_total"
                    type="text"
                    label="Grand Total"
                    onChange={(v) => {
                      setGrandTotal(v.target.value);
                    }}
                    onBlur={handleBlur}
                    value={Grand_total}
                  />
                </Box>
                <LoadingButton
                  // size="small"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Final Save
                </LoadingButton>
              </Paper>
            </>
          )}
        </Formik>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            const iProduct =
              JSON.parse(localStorage.getItem("iProducts")) || "";
            const newArray = iProduct.filter((item) => item.id != editUser.id);
            localStorage.setItem("iProducts", JSON.stringify(newArray));
            setIProducts(newArray);
            handleProduct(products, (arraY) => {
              let total = 0;
              arraY.map((item) => {
                total = total + parseFloat(item.price) * parseInt(item.qty);
              });
              setSubTotal(total);
              total = total - Discount;
              const gst = (total / 100) * gstPer;
              setGST(gst);
              setGrandTotal(total + gst);
            });
            handleCloseMenu();
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify icon={"ic:baseline-delete"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
