import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { useState } from 'react';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => {
          if(item.sub_nav.length > 0) { return (<DropdownLayout key={item.title} item={item} />)}
         return (
          <NavItem key={item.title} item={item} />
        );
        })}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item,op=false }) {
  const { title, path, icon, info } = item;
  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': !op?{
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        }:{},
      }}
    >
      {op?(<svg xmlns="http://www.w3.org/2000/svg" width="35" height="15" viewBox="0 0 15 15"><path fill="currentColor" d="M8.293 2.293a1 1 0 0 1 1.414 0l4.5 4.5a1 1 0 0 1 0 1.414l-4.5 4.5a1 1 0 0 1-1.414-1.414L11 8.5H1.5a1 1 0 0 1 0-2H11L8.293 3.707a1 1 0 0 1 0-1.414Z"/></svg>)
    :(
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
    )  
    }
     

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}

function DropdownLayout({ item }){
  const [open,setOpen]=useState(false);
  return (<Box sx={{border:'2px solid rgba(145, 158, 171, 0.16)'}}>
    <StyledNavItem
    component={RouterLink}
    onClick={()=>setOpen(!open)}
    sx={{
      '&.active': {
        color: 'text.primary',
        // bgcolor: 'action.selected',
        fontWeight: 'fontWeightBold',
        textAlign:'left',
        paddingLeft:2
      },
    }}
  >
  <ListItemText disableTypography primary={item.title} />
  </StyledNavItem>
  {
    open?(
      item.sub_nav.map((i,index)=>(<NavItem key={index} item={i} op={true} />))
    ):(<></>)
  }
  </Box>
  );
}
