import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useContext, useEffect, useState } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TextField,
  Box,
  Select,
  FormControl,
  InputLabel,
  Modal,
} from "@mui/material";
// components
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
// mock
import USERLIST from "../_mock/user";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "stock", label: "Stock", alignRight: false },
  { id: "unit", label: "Unit", alignRight: false },
  { id: "actual_price", label: "Actual Price", alignRight: false },
  { id: "gst", label: "GST %", alignRight: false },
  { id: "sell_price", label: "Sell Price", alignRight: false },

    // { id: 'status', label: 'Status', alignRight: false },
    { id: '', label:'Action' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ProductsPage({ context }) {
  const [add, openAdd] = useState(false);
  const [open, setOpen] = useState(null);
  const { products, getProducts, user, stateUpdate,getInventory, units, unitProvider ,addProductAPI,updateProductAPI} =
    useContext(context);
    const [totalActual,setActual]= useState(0);
    const [totalSell,setSell]= useState(0);
    const [totalStock,setStock]= useState(0);
  const navigate = useNavigate();
  
  const updateTotal = (data) => {
    let ATotal=0;
    let STotal=0;
    let TStock=0;
    data.map((item)=>{
        ATotal=ATotal+parseFloat(item.actual_price);
        STotal=STotal+parseFloat(item.sell_price);
        TStock=TStock+parseInt(item.quantity);
    })
    setActual(ATotal);
    setSell(STotal);
    setStock(TStock);
  };
  useEffect(() => {
    if (user.user_type != 1) {
      navigate("/app");
    } else {
      getProducts(updateTotal);
      getInventory();
    }
  }, []);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [editUser, setEditUser] = useState({});

  

  const handleOpenMenu = (event, data) => {
    setEditUser(data);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setEditUser({});
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = products.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

 

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;

  const filteredUsers = applySortFilter(
    products,
    getComparator(order, orderBy),
    filterName
  );

  const calculationComponent=(<div className="totalWrap"><Box style={{padding:10}}>
        <Button
         variant="contained"
        >
         Total Actual Price: {totalActual}
        </Button>
        </Box>
        <Box style={{padding:10}}>
        <Button
         variant="contained"
        >
         Total Sell Price: {totalSell}
        </Button>
        </Box>
        <Box style={{padding:10}}>
        <Button
         variant="contained"
        >
         Total Product Stock: {totalStock}
        </Button>
        </Box></div>
        );

        const [openModal, setOpenModal] = useState(false);
        const handleOpenModal = () => setOpenModal(true);
        const handleCloseModal = () => setOpenModal(false);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Products | Shree Smile </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Products
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon={add ? "ic:baseline-close" : "mdi:add"} />}
            onClick={() => {
              openAdd(!add);
            }}
          >
            {add ? "Close" : "Add Product"}
          </Button>
        </Stack>
        {add ? (
          <Paper elevation={2} sx={{ padding: 3, marginBottom: 5 }}>
            <Formik
              initialValues={{ name: "", unit: "",sell_price:0,actual_price:"",gst:0 }}
              validate={(values)=>{
                const errors={};
                if(!values.actual_price){
                    errors.actual_price="Required Actual Price";
                }else if(isNaN(values.actual_price)){
                    errors.actual_price="Actual Price must be a number";
                }else if(parseFloat(values.actual_price) <= 0){
                    errors.actual_price="Actual Price must be greater than zero";
                }
                // if(!values.sell_price){
                //     errors.sell_price="Required Sell Price";
                // }
                if(isNaN(values.sell_price)){
                    errors.sell_price="GST Price must be a number";
                }
                // else if(parseFloat(values.sell_price) <= 0){
                //     errors.sell_price="Sell Price must be greater than zero";
                // }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                addProductAPI(
                  { name: values.name,
                   unit: values.unit,
                   sell_price:values.actual_price,
                   actual_price:(parseFloat(values.actual_price)-parseFloat(values.sell_price)).toFixed(2),
                   gst:values.gst
                  },()=>{
                    getProducts(updateTotal);
                });
                openAdd(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
                /* and other goodies */
              }) => (
                <>
                  <Typography variant="h6" gutterBottom>
                    Product Details
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      padding: 5,
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                      marginBottom: 5,
                      marginTop: 5,
                      gap: 5,
                    }}
                  >
                    <TextField 
                     name="name"
                     type="text" 
                     label="Product Name"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.name}
                     required
                     />
                    <FormControl sx={{ minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-label">
                        Unit
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Unit"
                        name="unit"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.unit}
                        required
                      >
                        {units.map((item) => (
                          <MenuItem value={item.unit}>
                            {unitProvider(item.unit)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      name="actual_price"
                      label="Price + GST"
                      type="text"
                      onChange={(v)=>{handleChange(v);
                        const value=parseFloat(v.target.value);
                        const gst_v=(value/100)*parseInt(values.gst);
                        const sell=value+gst_v;
                        setFieldValue("sell_price",gst_v.toFixed(2));
                      }}
                        onBlur={handleBlur}
                        value={values.actual_price}
                        required
                    />
                    <FormControl sx={{ minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-label2">
                        GST%
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label2"
                        id="demo-simple-select"
                        label="GST%"
                        name="gst"
                        onChange={(v)=>{handleChange(v);
                          const value=parseInt(v.target.value);
                          const gst_v=(parseFloat(values.actual_price)/100)*value;
                          const sell=parseFloat(values.actual_price)+gst_v;
                          setFieldValue("sell_price",gst_v.toFixed(2));
                          }}
                        onBlur={handleBlur}
                        value={values.gst}
                        required
                      >
                          <MenuItem value={0}>No Cost GST</MenuItem>
                          <MenuItem value={5}>5%</MenuItem>
                          <MenuItem value={12}>12%</MenuItem>
                          <MenuItem value={18}>18%</MenuItem>
                          <MenuItem value={28}>28%</MenuItem>

                      </Select>
                    </FormControl>
                    <TextField
                      disabled
                      name="sell_price"
                      label="GST"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sell_price}
                      required
                    />
                  </Box>
                  <Stack spacing={1}>
                 <Typography> {errors.actual_price && touched.actual_price && errors.actual_price}</Typography>
                 <Typography>  {errors.sell_price && touched.sell_price && errors.sell_price}</Typography>
                  </Stack>
                  <LoadingButton size="small" type="submit" variant="contained" onClick={handleSubmit} >
                    Save
                  </LoadingButton>
                </>
              )}
            </Formik>
          </Paper>
        ) : (
          <></>
        )}

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            children={calculationComponent}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={products.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, name, unit, sell_price, actual_price,quantity } = row;
                      const selectedUser = selected.indexOf(name) !== -1;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedUser}
                              onChange={(event) => handleClick(event, name)}
                            />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{quantity}</TableCell>

                          <TableCell align="left">{unitProvider(unit)}</TableCell>

                          <TableCell align="left">{actual_price}</TableCell>
                          <TableCell align="left">{row.gst_per}%</TableCell>

                          <TableCell align="left">{sell_price}</TableCell>

                          {/* <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(s_msg)}</Label>
                        </TableCell> */}

                          <TableCell align="center">
                          <IconButton size="large" color="inherit" onClick={(e)=>handleOpenMenu(e,row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25,50,100]}
            component="div"
            count={products.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleOpenModal()
          }}
        >
          <Iconify icon={"bxs:edit"} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {/* <MenuItem
        disabled
          onClick={() => {
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify icon={"ic:baseline-delete"} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
      </Popover>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius:5,

  boxShadow: 24,
  p: 4,
}} >
         
            <Formik
              initialValues={{ id:editUser.id, name: editUser.name, unit: editUser.unit,sell_price:editUser.sell_price,actual_price:editUser.actual_price,gst:editUser.gst_per }}
              validate={(values)=>{
                const errors={};
                if(!values.actual_price){
                    errors.actual_price="Required Actual Price";
                }else if(isNaN(values.actual_price)){
                    errors.actual_price="Actual Price must be a number";
                }else if(parseFloat(values.actual_price) <= 0){
                    errors.actual_price="Actual Price must be greater than zero";
                }
                if(!values.sell_price){
                    errors.sell_price="Required Sell Price";
                }else if(isNaN(values.sell_price)){
                    errors.sell_price="Sell Price must be a number";
                }else if(parseFloat(values.sell_price) <= 0){
                    errors.sell_price="Sell Price must be greater than zero";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                    updateProductAPI(values,
                        updateTotal,
                        ()=>{
                        handleCloseModal();
                        handleCloseMenu();
                    });
                }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
                /* and other goodies */
              }) => (
                <>
                  <Typography variant="h6" gutterBottom>
                    Product Details
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      padding: 5,
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                      marginBottom: 5,
                      marginTop: 5,
                      gap: 5,
                    }}
                  >
                    <TextField 
                     name="name"
                     type="text" 
                     label="Product Name"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.name}
                     required
                     />
                    <FormControl sx={{ minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-label">
                        Unit
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Unit"
                        name="unit"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.unit}
                        required
                      >
                        {units.map((item) => (
                          <MenuItem value={item.unit}>
                            {unitProvider(item.unit)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      name="actual_price"
                      label="Actual Price"
                      type="text"
                      onChange={(v)=>{handleChange(v);
                        const value=parseFloat(v.target.value);
                        const gst_v=(value/100)*parseInt(values.gst);
                        const sell=value+gst_v;
                        setFieldValue("sell_price",sell.toFixed(2));
                      }}
                        onBlur={handleBlur}
                        value={values.actual_price}
                        required
                    />
                    <FormControl sx={{ minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-label2">
                        GST%
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label2"
                        id="demo-simple-select"
                        label="GST%"
                        name="gst"
                        onChange={(v)=>{handleChange(v);
                          const value=parseInt(v.target.value);
                          const gst_v=(parseFloat(values.actual_price)/100)*value;
                          const sell=parseFloat(values.actual_price)+gst_v;
                          setFieldValue("sell_price",sell.toFixed(2));
                          }}
                        onBlur={handleBlur}
                        value={values.gst}
                        required
                      >
                          <MenuItem value={0}>No Cost GST</MenuItem>
                          <MenuItem value={5}>5%</MenuItem>
                          <MenuItem value={12}>12%</MenuItem>
                          <MenuItem value={18}>18%</MenuItem>
                          <MenuItem value={28}>28%</MenuItem>

                      </Select>
                    </FormControl>
                    <TextField
                      disabled
                      name="sell_price"
                      label="Sell Price"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sell_price}
                      required
                    />
                  </Box>
                  <Stack spacing={1}>
                 <Typography> {errors.actual_price && touched.actual_price && errors.actual_price}</Typography>
                 <Typography>  {errors.sell_price && touched.sell_price && errors.sell_price}</Typography>
                  </Stack>
                  <LoadingButton size="small" type="submit" variant="contained" onClick={handleSubmit} >
                    Update
                  </LoadingButton>
                  <LoadingButton size="small" type="submit" variant="contained" onClick={handleCloseModal} style={{marginLeft:10}} >
                    Close
                  </LoadingButton>
                </>
              )}
            </Formik>
        </Box>
      </Modal>  

    </>
  );
}
