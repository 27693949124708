import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import Swal from "sweetalert2";
import { useContext, useEffect, useState } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TextField,
  Box,
  Select,
  FormControl,
  InputLabel,
  Modal,
  Autocomplete
} from "@mui/material";
// components
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
// mock
import USERLIST from "../_mock/user";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import * as Yup from 'yup';
const phoneRegExp= /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const CustomerSchema= Yup.object().shape({
    invoice_id: Yup.string().required('Required')
});

  function checksum(g){
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(g)
     if(regTest){
        let a=65,b=55,c=36,p=0;
        return Array['from'](g).reduce((i,j,k,g)=>{ 
           p=(p=(j.charCodeAt(0)<a?parseInt(j):j.charCodeAt(0)-b)*(k%2+1))>c?1+(p-c):p;
           return k<14?i+p:j==((c=(c-(i%c)))<10?c:String.fromCharCode(c+b));
        },0); 
    }
    return regTest
}

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Product Name", alignRight: false },
  { id: "qty", label: "Quantity", alignRight: false },
  { id: "unit", label: "Unit", alignRight: false },
  { id: "total_weight", label: "Total Weight", alignRight: false },
  { id: "gst_amount", label: "GST Amount", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
  { id: "total", label: "Total", alignRight: false },
  { id: 'action', label:'Action' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.product_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
function getDifference(array1, array2) {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.id === object2.id;
      });
    });
  }

export default function ReturnPage({ context }) {
  const [add, openAdd] = useState(false);
  const [open, setOpen] = useState(null);
  const [iProducts,setIProducts]=useState([])
  const {getReturnProducts,INVRproducts,returnInvoiceAPI,INVproducts,getProductsInvoiceAPI,invoices,invoicesAPI,configCatcher,gstPer,addInvoiceAPI, states,updateCustomerAPI,addCustomerAPI,getCustomer,customers,products,units, getProducts,deleteInventoryAPI, user, unitProvider ,addInventoryAPI,inventory,getInventory} =
    useContext(context);
  const navigate = useNavigate();
  const [dists,setDists]=useState([]);
  const [ProductState,setProdcutSate]=useState(products);
  const [Sub_total,setSubTotal]=useState(0);
  const [Gst,setGST]=useState(0); 
  const [Grand_total,setGrandTotal]=useState(0);
  const [Discount,setDiscount]=useState(0);

  const [allProducts,setAllproducts]=useState([]);

  const handleProduct=(p=products,callback)=>{
    try{
        const iProduct = JSON.parse(localStorage.getItem("iProducts")) || "";
        if (iProduct != "" && iProduct.length > 0) {
            setIProducts(iProduct);
            setProdcutSate(getDifference(p.filter((item)=>item.quantity > 0),iProduct));
            callback(iProduct);
          }
          else{
            setProdcutSate(p.filter((item)=>item.quantity > 0));
          setSubTotal(0);
        setGST(0);
        setGrandTotal(0);
        setIProducts([]);
          }
    }catch(e){
    }
  }
  useEffect(() => {
    configCatcher();
        getCustomer();
        getInventory();
        invoicesAPI();
        getProductsInvoiceAPI("");
        getReturnProducts();
        getProducts((v)=>{
            // handleProduct(v,(arraY)=>{

            //     let total=0;
            //                     arraY.map((item)=>{
            //                         total=total+(parseFloat(item.price)*parseInt(item.qty));
            //                     });
            //                     setSubTotal(total);
            //                     total=total-Discount;
            //                     const gst= (total/100)*gstPer;
            //                     setGST(gst);
            //                     setGrandTotal((total+gst));
            // });
        })
       
  }, []);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [editUser, setEditUser] = useState({});
  const [editQuantity, setEditQuantity] = useState();
  const [editLog, setEditLog] = useState([]);

  

  const handleOpenMenu = (event, data) => {
    setEditUser(data);
    setEditQuantity(data.qty);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setEditUser({});
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allProducts.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

 

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allProducts.length) : 0;

  const filteredUsers = applySortFilter(
    allProducts,
    getComparator(order, orderBy),
    filterName
  );



        const [openModal, setOpenModal] = useState(false);
        const handleOpenModal = () => setOpenModal(true);
        const handleCloseModal = () => setOpenModal(false);
        const [makeSet, setMake] = useState(false);

  const isNotFound = !filteredUsers.length && !!filterName;
  let currentDate = new Date().toJSON().slice(0, 10);

  const [customerID, setcustomerID] = useState(0);
  let ACustomer = [];
  customers.map((item) => {
    ACustomer.push({ label: item.c_name, value: item.c_id });
  });
  ACustomer=ACustomer.sort(function (a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <Helmet>
        <title> Invoice | Shree Smile </title>
      </Helmet>

      <Container>
          <Formik
              initialValues={{invoice_id:"",name:"",date:"",due_date:"",baddress:"",saddress:"",product:"",qty:"",delivery_date:currentDate,emp_id:user.id,gst:"",sub_total:"",grand_total:"",discount:"" }}
              validationSchema={CustomerSchema}
              onSubmit={(values, { resetForm }) => {
                if(editLog.length > 0){
                if(allProducts.length > 0){
                        Swal.fire({
                            position: "center",
                            icon: "warning",
                            title: "Warning",
                            text:`Please be advised that the current action involves a final submission. Once submitted, the content will be considered as a permanent record and will not be editable or deletable in the future. Take this opportunity to thoroughly review all information, ensuring its accuracy and completeness before proceeding.

                            We understand the importance of accurate and complete submissions, as they often serve as official records or representations. Kindly double-check all details to avoid any unintended errors or omissions. Should you have any doubts or concerns, please seek assistance prior to submitting.
                            
                            By proceeding with this submission, you acknowledge that the content will be considered final and unchangeable. Please exercise caution and diligence during this process.
                            
                            Thank you for your understanding and cooperation.`,   
                            showConfirmButton: true,
                            
                          }).then((result) => {
                            if (result.isConfirmed) {
                               const RequestOBJ={
                                  invoice_id:values.invoice_id,
                                  product_invoice:allProducts,
                                  sub_total:Sub_total,
                                  grand_total:Grand_total
                               };
                               console.log("RequestOBJ:",RequestOBJ);
                               returnInvoiceAPI(RequestOBJ);
                               navigate('/invoicelist');
                            }
                          });
                        }
                        else{
                            Swal.fire({
                                position: 'center',
                                icon: 'warning',
                                title: 'Please Add Product To Invoice',
                                showConfirmButton: false,
                                timer: 1500
                              }) 
                        } }
               
              }}
          
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                resetForm
                /* and other goodies */
              }) => (
                <>
                <Paper elevation={2} sx={{ padding: 3, marginBottom: 5 }}>
                  <Typography variant="h6" gutterBottom>
                    Invoice Details
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      padding: 5,
                      justifyContent:"space-evenly",
                      flexWrap: "wrap",
                      marginBottom: 5,
                      marginTop: 5,
                      gap: 5,
                    }}
                  >
                     <Autocomplete
                      disabled={makeSet}
                    disablePortal
                    id="combo-box-demo"
                    options={ACustomer}
                    name="name"
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        let value = newValue.value;
                        setcustomerID(newValue.value);
                      } else {
                        setcustomerID(0);
                      }
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Customer Name" />
                    )}
                  />
                    <FormControl sx={{ minWidth: 160 }} required>
                      <InputLabel id="demo-simple-select-label-name">
                       Invoice No
                      </InputLabel>
                      <Select
                        disabled={makeSet}
                        labelId="demo-simple-select-label-name"
                        id="demo-simple-select"
                        label="Invoice No"
                        name="invoice_id"
                        onChange={(v)=>{handleChange(v);
                            const value=v.target.value;
                            const Invoice=invoices.filter((item)=>item.invoice_id === value);
                            setFieldValue("date",Invoice[0].created_date);
                            setFieldValue("due_date",Invoice[0].due_date);
                            setAllproducts([]);
                            setAllproducts(INVproducts.filter((item)=>item.invoice_id === Invoice[0].invoice_id));
                            setSubTotal(Invoice[0].sub_total);
                            setGrandTotal(Invoice[0].total);
                            setDiscount(Invoice[0].discount);
                            setMake(true);

                        }}
                        onBlur={handleBlur}
                        value={values.invoice_id}
                        required
                      >
                        {invoices.filter((item)=>item.return_once == "0" && item.c_id == customerID).map((item) => (
                          <MenuItem value={item.invoice_id}>
                            #SSU-{item.invoice_no}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField 
                     disabled
                     name="date"
                     type="text" 
                     label="Date"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.date}
                     required
                     />
                     {/* <TextField 
                     disabled
                     name="due_date"
                     type="text" 
                     label="Due Date"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.due_date}
                     required
                     /> */}
                  </Box>
                </Paper>


        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={allProducts.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const selectedUser = selected.indexOf(row.name) !== -1;
                      return (
                        <TableRow
                          hover
                          key={row.p_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedUser}
                              onChange={(event) => handleClick(event, row.product_name)}
                            />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {row.product_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{row.qty}</TableCell>
                          <TableCell align="left">{unitProvider(row.weight)}</TableCell>
                          <TableCell align="left">{unitProvider(parseFloat(row.weight) * parseInt(row.qty))}</TableCell>
                          {/* <TableCell align="left">{row.delivery_date}</TableCell> */}
                          <TableCell align="left">{row.gst_per}</TableCell>
                          <TableCell align="left">{parseFloat(row.price).toFixed(2)}</TableCell>
                          
                          <TableCell align="left">{parseFloat(row.price) * parseInt(row.qty).toFixed(2)}</TableCell>
                          <TableCell align="center">
                          <IconButton size="large" color="inherit" onClick={(e)=>handleOpenMenu(e,row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25,50,100]}
            component="div"
            count={allProducts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <Paper elevation={2} sx={{ padding: 3, marginTop: 5 }}>
        
                  <Box
                    style={{
                      display: "flex",
                      padding: 5,
                      justifyContent:"space-evenly",
                      flexWrap: "wrap",
                      marginBottom: 5,
                      marginTop: 5,
                      gap: 5,
                    }}
                  > 
                  <TextField 
                     disabled
                     name="sub_total"
                     type="text" 
                     label="Sub Total"
                     onChange={(v)=>{setSubTotal(v.target.value)}}
                     onBlur={handleBlur}
                     value={Sub_total}
                     />
                     <TextField 
                     disabled
                     name="discount"
                     type="number" 
                     label="Discount"
                     onChange={(v)=>{
                        if(isNaN(v.target.value)){
                            alert("Discount Price must be a number");
                        }else if(parseFloat(v.target.value) < 0  || parseFloat(v.target.value) > Sub_total ){
                            alert("Discount Price must be >= zero");
                        }
                        else{
                        setDiscount(v.target.value);
                        const bb=Sub_total - parseFloat(v.target.value);
                        setGST((bb/100)*gstPer);
                        setGrandTotal(bb+((bb/100)*gstPer));
                        }
                    }}
                     onBlur={handleBlur}
                     value={Discount}
                     />
                     <TextField 
                     disabled
                     name="grand_total"
                     type="text" 
                     label="Grand Total"
                     onChange={(v)=>{setGrandTotal(v.target.value)}}
                     onBlur={handleBlur}
                     value={Grand_total}
                     />
                   </Box>        
        <LoadingButton size="small" type="submit" variant="contained" onClick={handleSubmit} >
                    Final Save 
        </LoadingButton>             
        </Paper>
        </>
          )}
          </Formik>
      </Container>
      
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >

        <MenuItem
          onClick={() => {
            handleOpenModal();
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify icon={"ic:baseline-delete"} sx={{ mr: 2 }} />
          Return 
        </MenuItem>
      </Popover>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title2"
        aria-describedby="modal-modal-description2"
      >
<Box
sx={{
  width:150,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  background:"white",
  borderRadius:3,
  padding:2
  }}
>

<TextField 
 name="date"
 type="text" 
 label="Return Quantity"
 value={editQuantity}
 onChange={(v)=>setEditQuantity(v.target.value)}
/>
<Button onClick={()=>{
    if(parseInt(editQuantity) <= parseInt(editUser.qty) && parseInt(editQuantity) > 0){
        const data=allProducts;
        const index=data.indexOf(editUser);
        let editQ=data[index].qty-editQuantity;
        data[index].qty=editQ;
        data[index].total_price=parseFloat(data[index].price) * parseInt(editQ);
        setAllproducts(data);
        const log=editLog;
        log.push(data[index]);
        setEditLog(log);
        let t=0;
        const Total=data.map((item)=>{
            t=t+parseFloat(item.total_price);
        })
        setSubTotal(t.toFixed(2));
        setGrandTotal((t.toFixed(2)-parseFloat(Discount)).toFixed(2));
        handleCloseModal();
        handleCloseMenu();
    }else{
        alert("Please enter valid Quantity!!!");
        handleCloseModal();
    }
}} sx={{backgroundColor:"blue",color:"white"}}>Return</Button>               
</Box>
      </Modal>
      

    </>
  );
}
