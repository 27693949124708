import React, { useContext } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import LoadingScreen from './screens/LoadingScreen';
import { Context } from './context/AppContextProvider';

import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import InventoryPage from './pages/InventoryPage';
import CustomerPage from './pages/CustomerPage';
import InvoicePage from './pages/InvoicePage';
import InvoiceListPage from './pages/InvoiceListPage';
import InvoicePDFTemp from './pages/InvoicePDFTemp';
import PaymentPage from './pages/PaymentPage';
import ReturnPage from './pages/ReturnPage';
import StockallPage from './pages/StockallPage';
import DatewiseSalesReport from './pages/DatewiseSalesReport';
import DailyReport from './pages/DailyReport';
import PaymentReport from './pages/PaymentReport';
import PaymentBFReport from './pages/PaymentBFReport';
import ReturnReport from './pages/ReturnReport';
import DailyOrderReport from './pages/DailyOrderReport';
import NewBilling from './pages/NewBilling';
import StockReport from './pages/StockReport';
import CustomerTransactionReport from './pages/CustomerTransactionReport';
import DailyPayment from './pages/DailyPayment';

const AppRoutes = () => {
const { isLogin,isLoading }=useContext(Context);
const routes=useRoutes([
    {
        path: '*',
        element: <Navigate to="/404" replace />,
    },
    
    {
        path:"/404",
        element: <SimpleLayout />,
        children: [
          { element: <Navigate to="/404/page-not-found" />, index: true },
          { path: 'page-not-found', element: <Page404 /> },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path:"/",
        element:isLogin?<DashboardLayout context={Context} />:<LoginPage context={Context} />,
        children:[
            { element: <Navigate to="/app" />, index: true },
            { path: 'app', element: <DashboardAppPage context={Context} /> },
            { path: 'user', element: <UserPage context={Context} /> },
            { path: 'products', element: <ProductsPage context={Context}  /> },
            { path: 'inventory', element: <InventoryPage context={Context}  /> },
            { path: 'customer', element: <CustomerPage context={Context}  /> },
            { path: 'invoice', element: <NewBilling context={Context} /> },
            { path: 'invoicelist', element: <InvoiceListPage context={Context} /> },
            { path: 'payments', element: <PaymentPage context={Context} /> },
            { path: 'return', element: <ReturnPage context={Context} /> },
            { path: 'stockall', element: <StockallPage context={Context} /> },
            { path: 'dailyreport', element: <DailyReport context={Context} /> },
            { path: 'dailyorderreport', element: <DailyOrderReport context={Context} /> },
            { path: 'paymentreport', element: <PaymentReport context={Context} /> },
            { path: 'paymentbfreport', element: <PaymentBFReport context={Context} /> },
            { path: 'userStatus', element: <DatewiseSalesReport context={Context} /> },
            { path: 'returnreport', element: <ReturnReport context={Context} /> },
            { path: 'stockreport', element: <StockReport context={Context} /> },
            { path: 'paymenttransactionreport', element: <CustomerTransactionReport context={Context} /> },
            { path: 'dailypaymentreport', element: <DailyPayment context={Context} /> },
          ],
    }
    // :{
    //         path: '/',
    //         element: <LoginPage context={Context} />,
    //  }
])
 return routes;
}

export default AppRoutes
