import React, { useState } from 'react'
import { styled } from '@mui/material'

const Container=styled("div")({
    marginBottom:10
})
const Table=styled("table")({
    border:"1px solid black",
    borderCollapse:"collapse",
    width:"100%"
})
const THead=styled("th")({
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:18,
    fontWeight:"bold"
})

const TData=styled("td")({
    padding:2,
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:14,
    fontWeight:"bold"
})


const PRequiremtTable = ({data}) => {
    
  return (
    <Container>
      <Table>
        <thead>
            <tr>
                <THead>INVOICE BY</THead>
                <THead>TOTAL ORDER</THead>
            </tr>
        </thead>
        <tbody>
            {
                data.map((item,i)=>(
                    <tr>
                        <TData style={{textAlign: "center"}}>{item.name}</TData>
                        <TData style={{textAlign: "center"}}>{item.orders}</TData>
                    </tr>
                ))
            }
        </tbody>
      </Table>
    </Container>
  )
}

export default PRequiremtTable
