import { styled } from "@mui/material";
import React from "react";
import { date } from "yup";
import StockReportTable from "./table/StockReportTable";

const MainContainer = styled("div")({
  padding: 5,
  justifyContent: "center",
  width: "100%",
});

const HeadBox = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const Box = styled("div")({
  width: "33%",
});

const Time=styled("div")({
    fontSize:10,
    fontWeight:"bold"
})

const TitleText=styled("div")({
    display:"flex",
    fontSize:14,
    fontWeight:"bold",
    width:'100%'
})

const MainText=styled("div")({
    fontSize:25,
    fontWeight:"bold"
})

const BarndText=styled("div")({
    display:"flex",
    fontSize:23,
    fontWeight:"bold",
    width:'100%',
    justifyContent:'center'
})

export default class StockReportModal extends React.Component {
    
  render() {
    
    return (
      <MainContainer>
        <HeadBox>
          <Box>
            <Time>{this.props.details.date}</Time>
            <TitleText>Form Date: {this.props.details.formDate}</TitleText>
            <TitleText>To Date: {this.props.details.ToDate}</TitleText>
            {/* <TitleText>Salesman: {this.props.details.salesman}</TitleText> */}
          </Box>
          <Box>
            <MainText>Daily Stock Report</MainText>
          </Box>
          <Box style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
           <BarndText>SMILE NAMKEEN</BarndText>
           <TitleText style={{justifyContent:'center'}}> Markona,Balasore</TitleText>
           <TitleText style={{justifyContent:'center'}}>8249239051</TitleText>
          </Box>
        </HeadBox>
        {/* <BFReportTable data={this.props.data} /> */}
        <StockReportTable data={this.props.data} unitProvider={this.props.unitProvider} />
      </MainContainer>
    );
  }
}



