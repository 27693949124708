import React, { useContext, useEffect, useState } from "react";
import "./billingpageStyle.css";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import { Autocomplete, Button, Table, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
const StyledRoot = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap:2
});

const Container = styled("div")({
  padding: 2,
});

const NewBilling = ({ context }) => {
  const {
    invoicesAPI,
    invoices,
    configCatcher,
    gstPer,
    addInvoiceAPI,
    getCustomer,
    customers,
    products,
    getProducts,
    user,
    unitProvider,
    getInventory,
  } = useContext(context);
  let currentDate = new Date().toJSON().slice(0, 10);
  const [iProducts, setIProducts] = useState([]);
  const [ProductState, setProdcutSate] = useState(products);
  const [Sub_total, setSubTotal] = useState(0);
  const [Gst, setGST] = useState(0);
  const [Grand_total, setGrandTotal] = useState(0);
  const [Discount, setDiscount] = useState(0);
  function getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.id === object2.id;
      });
    });
  }
  const BFCalculater = (inv) => {
    let bf = 0;
    inv.map((item) => {
      bf = bf + (parseFloat(item.total) - parseFloat(item.paid_amount));
    });
    return bf.toFixed(2);
  };
  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }
  const handleProduct = (p = products, callback) => {
    try {
      const iProduct = JSON.parse(localStorage.getItem("iProducts")) || "";
      if (iProduct != "" && iProduct.length > 0) {
        setIProducts(iProduct);
        setProdcutSate(
          getDifference(
            p.filter((item) => item.quantity > 0),
            iProduct
          )
        );
        callback(iProduct);
      } else {
        setProdcutSate(p.filter((item) => item.quantity > 0));
        setSubTotal(0);
        setGST(0);
        setGrandTotal(0);
        setIProducts([]);
      }
    } catch (e) {}
  };
  useEffect(() => {
    configCatcher();
    getCustomer();
    getInventory();
    getProducts((v) => {
      handleProduct(v, (arraY) => {
        let total = 0;
        arraY.map((item) => {
          total = total + parseFloat(item.price) * parseInt(item.qty);
        });
        setSubTotal(total);
        total = total - Discount;
        const gst = (total / 100) * gstPer;
        setGST(gst);
        setGrandTotal(total + gst);
      });
    });
    invoicesAPI();
  }, []);
  const [customerID, setcustomerID] = useState(0);
  const [prodcutID, setproductID] = useState(0);
  let ACustomer = [];
  customers.map((item) => {
    ACustomer.push({
      label: item.c_name + "/" + item.address_line1 + "/" + item.town,
      value: item.c_id,
    });
  });
  ACustomer = ACustomer.sort(function (a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
  let AProductState = [];
  ProductState.map((item) => {
    AProductState.push({
      label: item.name + "/-" + unitProvider(item.unit),
      value: item.id,
    });
  });
  const [valueProduct, setValueProduct] = useState([]);
  const [value, setValue] = useState(0);
  const handleDelete=(id)=>{
    const iProduct =
    JSON.parse(localStorage.getItem("iProducts")) || "";
  const newArray = iProduct.filter((item) => item.id != id);
  localStorage.setItem("iProducts", JSON.stringify(newArray));
  setIProducts(newArray);
  handleProduct(products, (arraY) => {
    let total = 0;
    arraY.map((item) => {
      total = total + parseFloat(item.price) * parseInt(item.qty);
    });
    setSubTotal(total);
    total = total - Discount;
    const gst = (total / 100) * gstPer;
    setGST(gst);
    setGrandTotal(total + gst);
  });
  }
  return (
    <>
      <StyledRoot>
        <Formik
          initialValues={{
            bf: "",
            name: "",
            date: currentDate,
            due_date: currentDate,
            baddress: "",
            saddress: "",
            product: "",
            price: 0,
            qty: 1,
            delivery_date: currentDate,
            emp_id: user.id,
            gst: "",
            sub_total: "",
            grand_total: "",
            discount: "",
          }}
          onSubmit={(values, { resetForm }) => {
            if (customerID != 0) {
              if (Discount != "" || Discount == 0) {
                if (iProducts.length > 0) {
                  // Swal.fire({
                  //   position: "center",
                  //   icon: "warning",
                  //   title: "Warning",
                  //   text: `Please be advised that the current action involves a final submission. Once submitted, the content will be considered as a permanent record and will not be editable or deletable in the future. Take this opportunity to thoroughly review all information, ensuring its accuracy and completeness before proceeding.

                  //           We understand the importance of accurate and complete submissions, as they often serve as official records or representations. Kindly double-check all details to avoid any unintended errors or omissions. Should you have any doubts or concerns, please seek assistance prior to submitting.
                            
                  //           By proceeding with this submission, you acknowledge that the content will be considered final and unchangeable. Please exercise caution and diligence during this process.
                            
                  //           Thank you for your understanding and cooperation.`,
                  //   showConfirmButton: true,
                  //   confirmButtonText:"Submit",
                  //   showCancelButton:true
                  // }).then((result) => {
                  //   if (result.isConfirmed) {
                      const RequestOBJ = {
                        name: customerID,
                        billing_date: values.date,
                        billing_address: values.baddress,
                        shipping_address: values.saddress,
                        product_invoice: iProducts,
                        sub_total: Sub_total,
                        discount: Discount,
                        gst_amount: Gst,
                        gst: gstPer,
                        due_date: values.due_date,
                        grand_total: Grand_total,
                        emp_id: values.emp_id,
                        invoice_last_bf: values.bf,
                      };
                      console.log("RequestOBJ:", RequestOBJ);
                      addInvoiceAPI(RequestOBJ, () => {
                        localStorage.setItem("iProducts", JSON.stringify([]));
                        setIProducts([]);
                        setSubTotal(0);
                        setGST(0);
                        setGrandTotal(0);
                        resetForm({
                          name: "",
                          date: currentDate,
                          due_date: currentDate,
                          baddress: "",
                          saddress: "",
                          product: "",
                          qty: "",
                          delivery_date: currentDate,
                          emp_id: user.id,
                          gst: "",
                          sub_total: "",
                          grand_total: "",
                          discount: "",
                        });

                        getProducts((v) => {
                          handleProduct(v, (arraY) => {
                            let total = 0;
                            arraY.map((item) => {
                              total = total + parseFloat(item.price) * parseInt(item.qty);
                            });
                            setSubTotal(total);
                            total = total - Discount;
                            const gst = (total / 100) * gstPer;
                            setGST(gst);
                            setGrandTotal(total + gst);
                          });
                        });

                      });
                  //   }
                  // });
                } else {
                  Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Please Add Product To Invoice",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              } else {
                alert("Discount Didnot Empty!!");
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm,
            /* and other goodies */
          }) => (
            <>
              <StyledRoot>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={ACustomer}
                  name="name"
                  onChange={(event, newValue) => {
                    if (newValue != null) {
                      let value = newValue.value;
                      setcustomerID(newValue.value);
                      const inv = invoices.filter((item) => {
                        let invoiceDate = new Date(item.created_at);
                        let Date9 = new Date(values.date);
                        const Date89 = addDays(Date9, 1);

                        return (
                          item.customer_id === value &&
                          invoiceDate < Date89 &&
                          item.payment_paid === "0"
                        );
                      });
                      const bf = BFCalculater(inv);
                      setFieldValue("bf", bf);
                    } else {
                      setFieldValue("bf", "");
                      setcustomerID(0);
                    }
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Customer Name" />
                  )}
                />
                <Container>
                  <input type="date" name="date" value={values.date} />
                  <input type="text" name="bf" value={values.bf} disabled />
                </Container>
              </StyledRoot>
              <StyledRoot>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo45"
                  value={valueProduct}
                  options={AProductState}
                  onChange={(e, newValue) => {
                    setValueProduct(newValue);
                    if (newValue != null) {
                      const value = newValue.value;
                      setproductID(value);
                      const product = products.filter(
                        (item) => item.id === value
                      );
                      setFieldValue("price", product[0].sell_price);
                    } else {
                      setFieldValue("price", "");

                      setproductID(0);
                    }
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Product" />
                  )}
                />
                <Box sx={{gap:3}}>
                 <Button variant="outlined" 
                 sx={{minWidth:20}}
                  onClick={()=>{
                    if(values.qty > 1){
                      let v= parseInt(values.qty) - 1;
                      if (prodcutID != 0) {
                        const obj = products.filter(
                          (item) => item.id === prodcutID
                        );
                        if (
                          parseInt(v) > parseInt(obj[0].quantity)
                        ) {
                          alert(
                            `That much Stock of ${obj[0].name} not Avilable!!!`
                          );
                        } else if (v < 0) {
                          alert("Negative  Value Not Allow!!!");
                        } else {
                          setFieldValue('qty',v);
                        }
                      } else {
                        alert("Please select product First!!!");
                      }
                    }
                  }}>-</Button>
                  <input
                
                    style={{width:50}}
                    // disabled
                    name="qty"
                    label="Quantity"
                    type="number"
                    onChange={(v) => {
                      if (prodcutID != 0) {
                        const obj = products.filter(
                          (item) => item.id === prodcutID
                        );
                        if (
                          parseInt(v.target.value) > parseInt(obj[0].quantity)
                        ) {
                          alert(
                            `That much Stock of ${obj[0].name} not Avilable!!!`
                          );
                        } else if (v.target.value < 0) {
                          alert("Negative  Value Not Allow!!!");
                        } else {
                          handleChange(v);
                        }
                      } else {
                        alert("Please select product First!!!");
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.qty}
                  />
                  <Button 
                   sx={{minWidth:20}}
                  variant="outlined"
                   onClick={()=>{
                    // if(values.qty > 1){
                      let v= parseInt(values.qty) + 1;
                      if (prodcutID != 0) {
                        const obj = products.filter(
                          (item) => item.id === prodcutID
                        );
                        if (
                          parseInt(v) > parseInt(obj[0].quantity)
                        ) {
                          alert(
                            `That much Stock of ${obj[0].name} not Avilable!!!`
                          );
                        } else if (v < 0) {
                          alert("Negative  Value Not Allow!!!");
                        } else {
                          setFieldValue('qty',v);
                        }
                      } else {
                        alert("Please select product First!!!");
                      }
                    // }
                  }}
                  >+</Button>
                  </Box>
                  <input
                  style={{width:60}}
                    name="price"
                    label="Price"
                    type="number"
                    onChange={(v) => {
                      if (prodcutID != 0) {
                        const obj = products.filter(
                          (item) => item.id === prodcutID
                        );
                        if (v.target.value < 0) {
                          alert("Negative  Value Not Allow!!!");
                        } else {
                          handleChange(v);
                        }
                      } else {
                        alert("Please select product First!!!");
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.price}
                  />
                   <LoadingButton
                    size="small"
                    type="button"
                    variant="contained"
                    onClick={() => {
                      if (
                        prodcutID != 0 &&
                        parseInt(values.qty) > 0 &&
                        values.delivery_date != "" &&
                        values.price != ""
                      ) 
                      {
                        const obj = products.filter(
                          (item) => item.id === prodcutID
                        );
                        const gwst =
                          (parseFloat(values.price) / 100) *
                          parseInt(obj[0].gst_per);
                        const pr = parseFloat(values.price);
                        const Gst =
                          parseFloat(obj[0].sell_price) -
                          parseFloat(obj[0].actual_price);
                        const setOBJ = {
                          id: obj[0].id,
                          name: obj[0].name,
                          actual_qty: obj[0].quantity,
                          qty: values.qty,
                          gst_amount: gwst.toFixed(2),
                          delivery_date: values.delivery_date,
                          unit: obj[0].unit,
                          price: pr.toFixed(2),
                        };
                        let arraY = iProducts;
                        arraY.push(setOBJ);
                        localStorage.setItem(
                          "iProducts",
                          JSON.stringify(arraY)
                        );
                        setIProducts(arraY);
                        setFieldValue("product", "");
                        setFieldValue("qty", "1");
                        setFieldValue("price", "");
                        setproductID(0);
                        setValueProduct([]);
                        handleProduct(products, (arra) => {
                          let total = 0;
                          arraY.map((item) => {
                            total =
                              total +
                              parseFloat(item.price) * parseInt(item.qty);
                          });
                          setSubTotal(total.toFixed(2));
                          total = total - Discount;
                          const gst = (total / 100) * gstPer;
                          setGST(gst);
                          setGrandTotal((total + gst).toFixed(2));
                        });
                      } else {
                        alert("Product,Price & Quantity Required ");
                      }
                    }}
                  >
                    Add
                  </LoadingButton>
              </StyledRoot>
              <Table sx={{ minWidth: 350}}>
               <TableHead>
                <TableRow>
                   <TableCell>#</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
                </TableHead>
                {
                  iProducts.map((row,i)=>(
                  <TableRow>
                      <TableCell><DeleteForeverIcon color="red" onClick={()=>{
                        handleDelete(row.id);
                      }} /></TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.qty}</TableCell>
                      <TableCell>{parseFloat(row.price).toFixed(2)}</TableCell>
                      <TableCell> {(
                                    parseFloat(row.price) * parseInt(row.qty)
                                  ).toFixed(2)}
                      </TableCell>
                      </TableRow>
                  )

                  )
                }
              </Table>
              <StyledRoot>
              <TextField
              sx={{width:100}}
                    disabled
                    name="sub_total"
                    type="text"
                    label="Sub Total"
                    onChange={(v) => {
                      setSubTotal(v.target.value);
                    }}
                    onBlur={handleBlur}
                    value={Sub_total}
                  />
                  <TextField
                  sx={{width:100}}
                    name="discount"
                    type="number"
                    label="Discount"
                    onChange={(v) => {
                      if (isNaN(v.target.value)) {
                        alert("Discount Price must be a number");
                      } else if (
                        parseFloat(v.target.value) < 0 ||
                        parseFloat(v.target.value) > Sub_total
                      ) {
                        alert("Discount Price must be >= zero");
                      } else {
                        setDiscount(v.target.value);
                        const bb = Sub_total - parseFloat(v.target.value);
                        setGST((bb / 100) * gstPer);
                        setGrandTotal(bb + (bb / 100) * gstPer);
                      }
                    }}
                    onBlur={handleBlur}
                    value={Discount}
                  />
              </StyledRoot>
              <Box
                sx={{
                  width: "100%",
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <BottomNavigation
                  showLabels
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  sx={{borderTopWidth:1,borderColor:'#000000'}}
                >
                    <TextField
                    disabled
                    name="grand_total"
                    type="text"
                    label="Grand Total"
                    onChange={(v) => {
                      setGrandTotal(v.target.value);
                    }}
                    onBlur={handleBlur}
                    value={Grand_total}
                  />

                  <Button variant="contained"
                  onClick={handleSubmit}
                  >Final Save</Button>
                </BottomNavigation>
              </Box>
            </>
          )}
        </Formik>
      </StyledRoot>
    </>
  );
};

export default NewBilling;
