import { styled } from '@mui/material'
import React from 'react'

const Container=styled("div")({

})
const Table=styled("table")({
    border:"1px solid black",
    borderCollapse:"collapse",
    width:"100%"
})
const THead=styled("th")({
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:18,
    fontWeight:"bold"
})

const TData=styled("td")({
    padding:2,
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:14,
    fontWeight:"bold"
})

const StockReportTable = ({data,unitProvider}) => {
    let Data=data.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      const handleTotal=(array)=>{
        let total=0;
        array.map((item)=>{
          total=total+parseInt(item.inventory_quantity);
        })
        return total;
      }
  return (
    <Container>
      <Table>
        <thead>
            <tr>
                <THead>#</THead>
                <THead>Product Name</THead>
                <THead>Unit</THead>
                <THead>Qunatity</THead>
                <THead>Time</THead>
            </tr>
        </thead>
        <tbody>
            {
                Data.map((item,i)=>(
                    <tr>
                        <TData style={{textAlign: "center"}}>{i+1}</TData>
                        <TData>{item.name}</TData>
                        <TData>{unitProvider(parseFloat(item.unit)*parseInt(item.inventory_quantity))}</TData>
                        <TData>{item.inventory_quantity}</TData>
                        <THead>{item.inventory_created}</THead>
                    </tr>
                ))
            }
            <tr>
                        <TData style={{textAlign: "center"}}></TData>
                        <TData></TData>
                        <TData></TData>
                        <TData>{handleTotal(Data)}</TData>
                        <THead></THead>
                    </tr>
        </tbody>
      </Table>
    </Container>
  )
}

export default StockReportTable
