import { styled } from '@mui/material'
import React from 'react'

const Container=styled("div")({

})
const Table=styled("table")({
    border:"1px solid black",
    borderCollapse:"collapse",
    width:"100%"
})
const THead=styled("th")({
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:18,
    fontWeight:"bold"
})

const TData=styled("td")({
    padding:2,
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:14,
    fontWeight:"bold"
})

const BFReportTable = ({data}) => {
    let Data=data.sort(function (a, b) {
        if (a.data.c_name < b.data.c_name) {
          return -1;
        }
        if (a.data.c_name > b.data.c_name) {
          return 1;
        }
        return 0;
      });

      const handleTotal=(array)=>{
        let totalAmount=0;
        let totalBf=0;
          array.map((item)=>{
            totalAmount=totalAmount+parseFloat(item.total);
            totalBf=totalBf+parseFloat(item.bf);
          })
          return {total:totalAmount,bf:totalBf};
      }

  return (
    <Container>
      <Table>
        <thead>
            <tr>
                <THead>#</THead>
                <THead>Customer Name</THead>
                <THead>Total Amount</THead>
                <THead>BF</THead>
            </tr>
        </thead>
        <tbody>
            {
                Data.map((item,i)=>(
                    <tr>
                        <TData style={{textAlign: "center"}}>{i+1}</TData>
                        <TData>{item.data.c_name}</TData>
                        <TData>{item.total}</TData>
                        <TData>{item.bf}</TData>
                    </tr>
                ))
            }
            <tr>
                        <TData style={{textAlign: "center"}}></TData>
                        <TData></TData>
                        <TData>{handleTotal(Data).total.toFixed(2)}</TData>
                        <TData>{handleTotal(Data).bf.toFixed(2)}</TData>
                    </tr>
        </tbody>
      </Table>
    </Container>
  )
}

export default BFReportTable
