
import React from "react";
import { styled } from "@mui/material";
import DailyReportTable from "./table/DailyReportTable";
import PRequiremtTable from "./table/PRequiremtTable";
import PRequiremtTable2 from "./table/PRequiremtTable2";
import './table/style/pdfstyle.css'
const MainContainer = styled("div")({
  padding: 5,
  justifyContent: "center",
  width: "100%",
});

const HeadBox = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const Box = styled("div")({
  width: "33%",
});

const Time=styled("div")({
    fontSize:10,
    fontWeight:"bold"
})

const TitleText=styled("div")({
    display:"flex",
    fontSize:14,
    fontWeight:"bold",
    width:'100%'
})

const MainText=styled("div")({
    fontSize:25,
    fontWeight:"bold"
})

const BarndText=styled("div")({
    display:"flex",
    fontSize:23,
    fontWeight:"bold",
    width:'100%',
    justifyContent:'center'
})



export default class ProductRequirment extends React.Component {
    

  render() {
    
    return (
      <MainContainer>
        <HeadBox>
          <Box>
            <Time>{this.props.details.date}</Time>
            <TitleText>Form Date: {this.props.details.formDate}</TitleText>
            <TitleText>To Date: {this.props.details.ToDate}</TitleText>
            <TitleText>Salesman: {this.props.details.salesman}</TitleText>
          </Box>
          <Box>
            <MainText>Product Requirement Report</MainText>
          </Box>
          <Box style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
           <BarndText>SMILE NAMKEEN</BarndText>
           <TitleText style={{justifyContent:'center'}}> Markona,Balasore</TitleText>
           <TitleText style={{justifyContent:'center'}}>8249239051</TitleText>
          </Box>
        </HeadBox>
       <PRequiremtTable  data={this.props.salesmanData}  />
       <PRequiremtTable2 productData={this.props.productData} inv={this.props.data} allInvoiceProduct={this.props.allInvoiceProduct} unit={this.props.unit}/>
      
       
      </MainContainer>
    );
  }
}



