import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useContext, useEffect, useState ,useRef} from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  TextField,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { useNavigate } from 'react-router-dom';
import InvoicePDFTemp from './InvoicePDFTemp';
import DataTable from '../components/DataTable';
import { MobilePDFReader } from 'react-read-pdf';
import ReactToPrint from 'react-to-print';
import Bill from "../component/print/Bill";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'print_status', label: 'Print Status', alignRight: false },
  { id: 'invoice_no', label: 'Invoice No', alignRight: false },
  { id: 'emp_id', label: 'Employee ID', alignRight: false },
  { id: 'c_name', label: 'Customer Name', alignRight: false },
  { id: 'phone', label: 'Phone No', alignRight: false },
  // { id: 'b_address', label: 'Billing Address', alignRight: false },
  // { id: 's_address', label: 'Shipping Address', alignRight: false },
  { id: 'c_date', label: 'Create Date', alignRight: false },
  // { id: 'd_date', label: 'Due Date', alignRight: false },
  { id: 'sub_total', label: 'Sub Total', alignRight: false },
  { id: 'discount', label: 'Discount', alignRight: false },
  // { id: 'gst', label: 'GST(%)', alignRight: false },
  // { id: 'gst_total', label: 'GST', alignRight: false },
  { id: 'grand Total', label: 'Grand Total', alignRight: false },
  { id: 'paid', label: 'Paid Amount', alignRight: false },
  { id: 'u_paid', label: 'UnPaid Amount', alignRight: false },
  { id: '', label:'Action' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => (_user.name.toLowerCase().indexOf(query.toLowerCase())) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function InvoiceListPage({context}) {
  const [open, setOpen] = useState(null);
  const {printedStatusAPI,INVproducts,INVRproducts,unitProvider,invoices,invoicesAPI, employeeList,getEmployee,user,stateUpdate,getProductsInvoiceAPI,getReturnProducts,deleteInvoiceAPI }=useContext(context);
  const navigate=useNavigate();
  useEffect(()=>{
    getEmployee()
    invoicesAPI()
    getProductsInvoiceAPI("");
    getReturnProducts();
  },[])
  const updateList= async ()=>{
    await getEmployee();
    await invoicesAPI();
    await getProductsInvoiceAPI("");
    await getReturnProducts();
  }
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [userID,setuserId]=useState([]);

  const handleOpenMenu = (event,id) => {
    setuserId(id);
    setOpen(event.currentTarget);
    handleCalculation( INVproducts.filter(
      (item) => item.invoice_id == id.invoice_id
    ),id);
  };

  const handleCloseMenu = () => {
    setuserId([]);
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = invoices.map((n) => n.full_name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invoices.length) : 0;

  const filteredUsers = applySortFilter(invoices, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal2 = () => setOpenModal2(true);
  const handleCloseModal2 = () => setOpenModal2(false);
  let componentRef = useRef();

  //

  const [T5,setT5]=useState(0.00);
  const [T12,setT12]=useState(0.00);
  const [T18,setT18]=useState(0.00);
  const [T28,setT28]=useState(0.00);

  const [TG5,setTG5]=useState(0.00);
  const [TG12,setTG12]=useState(0.00);
  const [TG18,setTG18]=useState(0.00);
  const [TG28,setTG28]=useState(0.00);

  const [T,setT]=useState(0.00);
  const [TG,setTG]=useState(0.00);
  const [TSG,setTSG]=useState(0.00);
  const [TCG,setTCG]=useState(0.00);
  const [TIG,setTIG]=useState(0.00);

 

  const handleCalculation=(products,data)=>{
    const handleStateCheck=(v)=>{
      let value=Math.round(v);
      if(data.c_state == "Odisha"){
         return {
              cgst:(value/2).toFixed(2),
              sgst:(value/2).toFixed(2),
              igst:0.00
         }
      }else{
         return {
          cgst:0.00,
          sgst:0.00,
          igst:value.toFixed(2)
         } 
      }
    }
    let t5=0;
    let t12=0;
    let t18=0;
    let t28=0;

    let tg5=0;
    let tg12=0;
    let tg18=0;
    let tg28=0;

    products.forEach((item)=>{
        const gst=Math.round((parseFloat(item.gst_per).toFixed(2)*100)/parseFloat(item.price));
        if(gst == 5){
            t5= t5 + parseFloat(item.total_price);
            // tg5= tg5 + parseFloat(item.gst_per)*parseInt(item.qty);
            tg5= tg5 + ((parseFloat(item.price)-((parseFloat(item.price)/105)*100))*parseFloat(item.qty));
        }else if( gst == 12){
            t12= t12 + parseFloat(item.total_price);
            tg12= tg12 + ((parseFloat(item.price)-((parseFloat(item.price)/112)*100))*parseFloat(item.qty));
        }else  if( gst == 18){
            t18= t18 + parseFloat(item.total_price);
            tg18= tg18 + ((parseFloat(item.price)-((parseFloat(item.price)/118)*100))*parseFloat(item.qty));
        }else  if( gst == 28){
            t28= t28 + parseFloat(item.total_price);
            tg28= tg28 + ((parseFloat(item.price)-((parseFloat(item.price)/128)*100))*parseFloat(item.qty));
        }else{

        }
    })
    setT5(t5.toFixed(2))
    setT12(t12.toFixed(2))
    setT18(t18.toFixed(2))
    setT28(t28.toFixed(2))
    setT((t5+t12+t18+t28).toFixed(2))

    setTG5(Math.round(tg5.toFixed(2)))
    setTG12(Math.round(tg12.toFixed(2)))
    setTG18(tg18.toFixed(2))
    setTG28(tg28.toFixed(2))
    setTG(Math.round((tg5+tg12+tg18+tg28).toFixed(2)))

    let tsgst=parseFloat(handleStateCheck(tg5).sgst)+parseFloat(handleStateCheck(tg12).sgst)+parseFloat(handleStateCheck(tg18).sgst)+parseFloat(handleStateCheck(tg28).sgst);
    let tcgst=parseFloat(handleStateCheck(tg5).cgst)+parseFloat(handleStateCheck(tg12).cgst)+parseFloat(handleStateCheck(tg18).cgst)+parseFloat(handleStateCheck(tg28).cgst);
    let tigst=parseFloat(handleStateCheck(tg5).igst)+parseFloat(handleStateCheck(tg12).igst)+parseFloat(handleStateCheck(tg18).igst)+parseFloat(handleStateCheck(tg28).igst);
   

    setTSG(tsgst.toFixed(2));
    setTCG(tcgst.toFixed(2));
    setTIG(tigst.toFixed(2));

    // callback({
    //     sgst:tsgst.toFixed(2),
    //     cgst:tcgst.toFixed(2),
    //     igst:tigst.toFixed(2)
    // })

  }

  //

  return (
    <>
      <Helmet>
        <title> Invoice | Shree Smile </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Invoices 
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="mdi:refresh" />} onClick={updateList}>
           Refresh
          </Button>
        </Stack>

        <Card>
          <UserListToolbar placeholder="Search Customer..." numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={invoices.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    
                    const selectedUser = selected.indexOf(row.invoice_no) !== -1;
                    
                    return (<>
                      <TableRow hover key={row.invoice_no} tabIndex={-1} role="checkbox" selected={selectedUser} >
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event,row.invoice_no )} />
                        </TableCell>
                        <TableCell align="left">{
                          row.invoice_printed == 1 ? (<span style={{color:"red"}} >Not Printed</span>) :(<span style={{color:"green"}}>Printed</span>)
                        }</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {"#SSU-"+row.invoice_no}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{row.employee_id}/{row.full_name}</TableCell>
                        
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.phone}</TableCell>
                        {/* <TableCell align="left">{row.billing_address}</TableCell> */}
                        {/* <TableCell align="left">{row.shipping_address}</TableCell> */}
                        <TableCell align="left">{row.created_date}</TableCell>
                        {/* <TableCell align="left">{row.due_date}</TableCell> */}
                        <TableCell align="left">{row.sub_total}</TableCell>
                        <TableCell align="left">{row.discount}</TableCell>
                        {/* <TableCell align="left">{row.gst+"%"}</TableCell> */}
                        {/* <TableCell align="left">{row.gst_total}</TableCell> */}
                        <TableCell align="left">{row.total}</TableCell>
                        <TableCell align="left">{row.paid_amount}</TableCell>
                        <TableCell align="left">{(parseFloat(row.total)-parseFloat(row.paid_amount)).toFixed(2)}</TableCell>
                        {/* <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(s_msg)}</Label>
                        </TableCell> */}
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e)=>handleOpenMenu(e,row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      </>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25,50,100]}
            component="div"
            count={invoices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={()=>{
         
          
        }}>
        <ReactToPrint
        trigger={() => <Button variant="contained">Print</Button>}
        content={() => componentRef}
        onAfterPrint={()=>{console.log("Printed After");
        printedStatusAPI(userID.invoice_id,()=>{
          updateList();
           handleCloseMenu();
        });

      }}
                          />
        </MenuItem>
        {/* <MenuItem onClick={()=>{
         handleOpenModal();
        }}>
          <Iconify icon={'mdi:download'} sx={{ mr: 2 }} />
          Download
        </MenuItem> */}
        {userID.return_once == "0"?(<>{userID.paid_amount == "0.00"?(
 <MenuItem
 onClick={()=>{
  deleteInvoiceAPI({invoice_id:userID.invoice_id},()=>{
    updateList();
  });
}} sx={{color:'red'}}>
  <Iconify icon={'ic:baseline-delete'} sx={{ mr: 2 }} />
  Delete
</MenuItem>

        ):(<></>)}</>):(<></>)}
       
      </Popover>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
            width:150,
            // width:600,
            // height:"90%",
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 24,
            background:"white",
            borderRadius:3,
            padding:2
            }}>
        <InvoicePDFTemp data={userID} context={context} />
        </Box>
      </Modal>
{/* //Return  */}
      <Modal
        open={openModal2}
        onClose={handleCloseModal2}
        aria-labelledby="modal-modal-title2"
        aria-describedby="modal-modal-description2"
      >
<Box
sx={{
  width:"90%",
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  background:"white",
  borderRadius:3,
  padding:2
  }}
>

                <Paper elevation={2} sx={{ padding: 3, marginBottom: 5 }}>
                  <Typography variant="h6" gutterBottom>
                    Customer Details
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      padding: 5,
                      justifyContent:"space-evenly",
                      flexWrap: "wrap",
                      marginBottom: 5,
                      marginTop: 5,
                      gap: 5,
                    }}
                  >
                    {/* <FormControl sx={{ minWidth: 160 }} required>
                      <InputLabel id="demo-simple-select-label-name">
                        Customer Name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-name"
                        id="demo-simple-select"
                        label="Customer Name"
                        name="name"
                        value={values.name}
                        required
                      >
                        {customers.map((item) => (
                          <MenuItem value={item.c_id}>
                            {item.c_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField 
                     name="date"
                     type="date" 
                     label="Date"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.date}
                     required
                     />
                     <TextField 
                     name="due_date"
                     type="date" 
                     label="Due Date"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.due_date}
                     required
                     /> */}
                    <TextField 
                     name="baddress"
                     type="text" 
                     label="Invoice No"
                    //  onChange={handleChange}
                    //  onBlur={handleBlur}
                    //  value={values.baddress}
                     required
                     />
                      <TextField 
                     name="saddress"
                     type="text" 
                     label="Customer Name"
                    //  onChange={handleChange}
                    //  onBlur={handleBlur}
                    //  value={values.saddress}
                     required
                     />
                     
                  </Box>
                </Paper>

<DataTable data={userID} context={context} />
</Box>
      </Modal>
      <div style={{display:"none"}}>
          <Bill
          
          TG={TG}
                TIG={TIG}
                TCG={TCG}
                TSG={TSG}
                T={T}
                TG12={TG12}
                TG5={TG5}
                T12={T12}
                T5={T5}

          
          
          inv={userID} product={
            INVproducts.filter(
              (item) => item.invoice_id == userID.invoice_id
            )
          }
          returnProduct={
            INVRproducts.filter(
              (item) => item.invoice_id == userID.invoice_id
            )
          }
          unitProvider={unitProvider}
           ref={(el)=>(componentRef=el)}/>
      </div>
    </>
  );
}
