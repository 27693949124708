import React from "react";
import { CirclesWithBar } from "react-loader-spinner";

const LoadingScreen = () => {
  return (
    <div
      style={{ height: "100vh",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:"transparent" }}
    >
      <CirclesWithBar
        height="100"
        width="100"
        color="#4fa94d"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        outerCircleColor=""
        innerCircleColor=""
        barColor=""
        ariaLabel="circles-with-bar-loading"
      />
    </div>
  );
};

export default LoadingScreen;
