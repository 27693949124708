import React from 'react'

import { styled } from '@mui/material'


const Container=styled("div")({

})
const Table=styled("table")({
    border:"1px solid black",
    borderCollapse:"collapse",
    width:"100%"
})
const THead=styled("th")({
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:18,
    fontWeight:"bold"
})

const TData=styled("td")({
    padding:0,
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:12,
    fontWeight:"bold",
    textAlign:"center"
})


const DailyPaymentTable = ({data}) => {
   
    const handleTotal=(array)=>{
        let total=0;
        array.map((item)=>{
            total=total+ parseFloat(item.payment_cus_paid_amount);
        })
        return total;
    }

  return (
    <Container>
    <Table>
      <thead>
          <tr>
              <THead>#</THead>
              <THead>Date</THead>
              <THead>Customer</THead>
              <THead>Payment Paid</THead>
          </tr>
      </thead>
      <tbody>
          {
              data.map((item,i)=>{ 
                return (
                  <tr>
                      <TData style={{textAlign: "center"}}>{i+1}</TData>
                      <TData>{item.payment_cus_created}</TData>
                      <TData>{item.payment_cus_name}</TData>
                      <TData>{item.payment_cus_paid_amount}</TData>
                  </tr>
              )}
              )
          }
           <tr>
                      <TData style={{textAlign: "center"}}></TData>
                      <TData></TData>
                      <TData></TData>
                      <TData>{handleTotal(data)}</TData>
                  </tr>
      </tbody>
    </Table>
  </Container>
  )
}

export default DailyPaymentTable
