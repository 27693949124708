import { styled } from "@mui/material";
import React,{useEffect,useState} from "react";
import BFReportTable from "./table/BFReportTable";

const MainContainer = styled("div")({
  padding: 18,
  justifyContent: "center",
  width: "100%",
  paddingLeft:18,
  paddingRight:18
});

const HeadBox = styled("div")({
  display: "flex",
  justifyContent: "center",
  height:"100%",
  width:"80%"
});

const Box = styled("div")({
  width: "33%",
  border:"1px solid black",
  padding:4
});

const NormalText=styled("div")({
    fontSize:12,
    fontWeight:"bold"
})

const TitleText=styled("div")({
    display:"flex",
    fontSize:17,
    fontWeight:"bold",
    width:'100%'
})

const MainText=styled("div")({
    fontSize:25,
    fontWeight:"bold"
})

const BarndText=styled("div")({
    display:"flex",
    fontSize:23,
    fontWeight:"bold",
    width:'100%',
    justifyContent:'center'
})

const CoustomerBox=styled("div")({
    border:"1px solid black",
    padding:4,
    width:"50%",
    height:"100%"
})

const TopWrapper=styled("div")({
    display:'flex',
    flexWrap:'nowrap',
    justifyContent:'center',
    width:"100%",
    height:135,
    borderCollapse:'collapse'
})

const Image=styled("img")({
    width:120,
    height:100
})

const TableWrapper=styled("div")({
    display:"flex",
    width:"100%",
    justifyContent:'center',
    borderCollapse:'collapse'
})

const PriceTable=styled("div")({
    width:"30%",
    border:"1px solid black",
    padding:3,
    paddingLeft:4

})

const PriceText=styled("div")({
    fontSize:14,
    fontWeight:'bold'
})

export default class Bill extends React.Component {

render() {
    return (
      <MainContainer>
        <TopWrapper>
        <Box>
            <NormalText>M/S SHREE SHAKTI UDYOG</NormalText>
            <NormalText>GSIN: 21BBAPB7939M1ZO</NormalText>
            <TitleText>MOB: 8249239051</TitleText>
            <NormalText>Markona,Balasore,Odisha</NormalText>
            <NormalText>Pin-756126</NormalText>
          </Box>
           
        <HeadBox>
          <Box>
           <NormalText>Invoice ID: #SSU-{this.props.inv.invoice_no}</NormalText>
           <NormalText>Date: {this.props.inv.created_date}</NormalText>
          </Box>
          <CoustomerBox>
                <TitleText>{this.props.inv.name}</TitleText>
                <NormalText>Address: {this.props.inv.address_line1+"," + this.props.inv.town+ "," + this.props.inv.c_state+""}</NormalText>
                <NormalText>Phone No: +91{this.props.inv.phone}</NormalText>
                <NormalText>GST: {this.props.inv.gst}</NormalText>
            </CoustomerBox>
          <Box style={{display:'flex',justifyContent:'center',flexWrap:'wrap',border:'none'}}>
             <Image  src="logo.png" />
          </Box>
        </HeadBox>
        </TopWrapper>
        <ProductTable data={this.props.product} returndata={this.props.returnProduct} unitP={this.props.unitProvider} inv={this.props.inv}/>
        <TableWrapper>
            <div style={{width:"70%"}}>
            <GstTable 
                TG={this.props.TG}
                TIG={this.props.TIG}
                TCG={this.props.TCG}
                TSG={this.props.TSG}
                T={this.props.T}
                TG12={this.props.TG12}
                TG5={this.props.TG5}
                T12={this.props.T12}
                T5={this.props.T5}
             data={this.props.inv}  callback={(obj)=>{}} />
             <PriceText>Term and Conditions</PriceText>
             <PriceText>1.Check first before receiving the product.No complane for shortage.</PriceText>
             <PriceText>2.Return damaged product within 90 days from the date of manufacturing.Replacement will be within 60 days</PriceText>
            </div>
            <PriceTable>
                {
                    this.props.inv.c_state == "Odisha" ? (<>
                     <PriceText>Sub Total:{(parseFloat(this.props.inv.sub_total)-(parseFloat(this.props.TCG)*2)).toFixed(2)} </PriceText>
                <PriceText>CGST Total: {parseFloat(this.props.TCG).toFixed(2)} </PriceText>
                <PriceText>SGST Total: {parseFloat(this.props.TSG).toFixed(2)}</PriceText>
                    </>):(<>
                <PriceText>Sub Total: {(parseFloat(this.props.inv.sub_total)-parseFloat(this.props.TIG)).toFixed(2)} </PriceText>
                <PriceText>IGST Total: {parseFloat(this.props.TIG).toFixed(2)} </PriceText>
                    </>)
                }
               <PriceText>Discount: {parseFloat(this.props.inv.discount).toFixed(2)}</PriceText>
               <PriceText>Total: {parseFloat(this.props.inv.total).toFixed(2)}</PriceText>
               <PriceText>BF: {parseFloat(this.props.inv.invoice_last_bf).toFixed(2)}</PriceText>
               <PriceText style={{borderTop:"1px solid black"}}>Grand Total: {(parseFloat(this.props.inv.total)+parseFloat(this.props.inv.invoice_last_bf)).toFixed(2)}</PriceText>
            </PriceTable>
        </TableWrapper>
      </MainContainer>
    );
  }
}


const Container=styled("div")({

})
const Table=styled("table")({
    border:"1px solid black",
    borderCollapse:"collapse",
    width:"100%"
})
const THead=styled("th")({
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:15,
    fontWeight:"bold",
    textAlign: "center"
})

const TData=styled("td")({
    padding:1,
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:14,
    fontWeight:"bold",
    textAlign: "center"
})


const ProductTable=({data,returndata,unitP,inv})=>{
    let tweight=0;
    return (<Container>
                 <Table>
        <thead>
            <tr>
                <THead>PRODUCT</THead>
                <THead>PCS</THead>
                <THead>WEIGHT</THead>
                <THead>GST%</THead>
                <THead>PRICE</THead>
                <THead>TOTAL</THead>
            </tr>
        </thead>
        <tbody>
            {
                data.map((item,i)=> {
                    tweight=tweight+parseFloat(item.weight) * parseInt(item.qty);
                    
                    return (
                    <tr>
                        <TData>{item.product_name}</TData>
                        <TData>{item.qty}</TData>
                        <TData>{
                                unitP(parseFloat(item.weight) * parseInt(item.qty))
                               }</TData>
                        <TData>{Math.round((parseFloat(item.gst_per).toFixed(2)*100)/parseFloat(item.price))}%</TData>
                        <TData>{parseFloat(item.price).toFixed(2)}</TData>
                        <TData>{parseFloat(item.total_price).toFixed(2)}</TData>
                    </tr>
                )})
            }
            {
                returndata.map((item,i)=> {
                    tweight=tweight-parseFloat(item.weight) * parseInt(item.qty);
                    
                    return (
                    <tr>
                        <TData>{item.product_name+"("+"Return Date:"+item.created_at+")"}</TData>
                        <TData>{item.qty}</TData>
                        <TData>{
                                unitP(parseFloat(item.weight) * parseInt(item.qty))
                               }</TData>
                        <TData>{Math.round((parseFloat(item.gst_per).toFixed(2)*100)/parseFloat(item.price))}%</TData>
                        <TData>{parseFloat(item.price).toFixed(2)}</TData>
                        <TData>{parseFloat(item.total_price).toFixed(2)}</TData>
                    </tr>
                )}) 
            }
            <tr>
                <TData></TData>
                <TData></TData>
                <TData>{unitP(tweight)}</TData>
                <TData></TData>
                <TData>Total</TData>
                <TData>{parseFloat(inv.sub_total).toFixed(2)}</TData>
            </tr>

        </tbody>
      </Table>
            </Container>);
}

const GstTable=({T5,T12,TG5,TG12,T,TSG,TCG,TIG,TG,data,callback})=>{

      const handleStateCheck=(v)=>{
        let value=Math.round(v);
        if(data.c_state == "Odisha"){
           return {
                cgst:(value/2).toFixed(2),
                sgst:(value/2).toFixed(2),
                igst:0.00
           }
        }else{
           return {
            cgst:0.00,
            sgst:0.00,
            igst:value.toFixed(2)
           } 
        }
      }
    return(<Container>
                <Table>
        <thead>
            <tr>
                <THead>CLASS</THead>
                <THead>TOTAL</THead>
                <THead>SGST</THead>
                <THead>CGST</THead>
                <THead>IGST</THead>
                <THead>TOTAL GST</THead>
            </tr>
        </thead>
        <tbody>
           <tr>
            <TData>GST 5%</TData>
            <TData>{T5}</TData>
            <TData>{handleStateCheck(TG5).sgst}</TData>
            <TData>{handleStateCheck(TG5).cgst}</TData>
            <TData>{handleStateCheck(TG5).igst}</TData>
            <TData>{TG5}</TData>
           </tr>
           <tr>
            <TData>GST 12%</TData>
            <TData>{T12}</TData>
            <TData>{handleStateCheck(TG12).sgst}</TData>
            <TData>{handleStateCheck(TG12).cgst}</TData>
            <TData>{handleStateCheck(TG12).igst}</TData>
            <TData>{TG12}</TData>
           </tr>
           <tr>
            <TData>TOTAL</TData>
            <TData>{T}</TData>
            <TData>{TSG}</TData>
            <TData>{TCG}</TData>
            <TData>{TIG}</TData>
            <TData>{TG}</TData>
           </tr>
            </tbody> </Table>
           </Container>)
}



