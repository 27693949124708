// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "Master",
    path: "/app",
    icon: icon("ic_analytics"),
    sub_nav: [
      {
        title: "Dashboard",
        path: "/app",
        icon: icon("ic_analytics"),
      },
      {
        title: "User Details",
        path: "/user",
        icon: icon("ic_user")
      },
      {
        title: "Customer Details",
        path: "/customer",
        icon: icon("ic_customer"),
      },
      {
        title: "Product Details",
        path: "/products",
        icon: icon("ic_cart")
      },
    ]
  },
  {
    title: "Sales",
    path: "/app",
    icon: icon("ic_analytics"),
    sub_nav: [
      {
        title: "Billing",
        path: "/invoice",
        icon: icon("ic_account")
      },
      {
        title: "Invoices",
        path: "/invoicelist",
        icon: icon("folder")
      },
      {
        title: "Invoice Return",
        path: "/return",
        icon: icon("return")
      },
    ]
  },
  {
    title: "Account",
    path: "/app",
    icon: icon("ic_analytics"),
    sub_nav: [
      {
        title: "Payment",
        path: "/payments",
        icon: icon("bill")
      }
    ]
  },
  {
    title: "Report",
    path: "/app",
    icon: icon("ic_analytics"),
    sub_nav: [
      {
        title: "Daily Report",
        path: "/dailyreport",
        icon: icon("bill")
      },
      {
        title: "Daily Stock Report",
        path: "/stockreport",
        icon: icon("bill")
      },
      {
        title: "Product Requirement Report",
        path: "/dailyorderreport",
        icon: icon("bill")
      },
      {
        title: "Customer Transaction Report",
        path: "/paymentreport",
        icon: icon("bill")
      },
      {
        title: "Customer B.F Report",
        path: "/paymentbfreport",
        icon: icon("bill")
      },
      {
        title: "Return Report",
        path: "/returnreport",
        icon: icon("bill")
      }
    ]
  },
  {
    title: "Product",
    path: "/app",
    icon: icon("ic_analytics"),
    sub_nav: [
      {
        title: "Add Product",
        path: "/inventory",
        icon: icon("ic_inventory"),
      },
      {
        title: "Stock Product",
        path: "/stockall",
        icon: icon("ic_inventory"),
      }
    ]
  }
];

export default navConfig;

export const navConfigEmp = [
  {
    title: "Master",
    path: "/app",
    icon: icon("ic_analytics"),
    sub_nav: [
      {
        title: "Dashboard",
        path: "/app",
        icon: icon("ic_analytics"),
      },
      {
        title: "Customer Details",
        path: "/customer",
        icon: icon("ic_customer"),
      }
    ]
  },
  {
    title: "Sales",
    path: "/app",
    icon: icon("ic_analytics"),
    sub_nav: [
      {
        title: "Billing",
        path: "/invoice",
        icon: icon("ic_account")
      },
      {
        title: "Invoices",
        path: "/invoicelist",
        icon: icon("folder")
      },
      {
        title: "Invoice Return",
        path: "/return",
        icon: icon("return")
      },
    ]
  },
  {
    title: "Account",
    path: "/app",
    icon: icon("ic_analytics"),
    sub_nav: [
      {
        title: "Payment",
        path: "/payments",
        icon: icon("bill")
      }
    ]
  },
  {
    title: "Report",
    path: "/app",
    icon: icon("ic_analytics"),
    sub_nav: [
      {
        title: "Daily Report",
        path: "/dailyreport",
        icon: icon("bill")
      },
      {
        title: "Daily Stock Report",
        path: "/stockreport",
        icon: icon("bill")
      },
      {
        title: "Product Requirement Report",
        path: "/dailyorderreport",
        icon: icon("bill")
      },
      {
        title: "Customer Transaction Report",
        path: "/paymentreport",
        icon: icon("bill")
      },
      {
        title: "Customer B.F Report",
        path: "/paymentbfreport",
        icon: icon("bill")
      },
      {
        title: "Return Report",
        path: "/returnreport",
        icon: icon("bill")
      }
    ]
  },
  {
    title: "Product",
    path: "/app",
    icon: icon("ic_analytics"),
    sub_nav: [
      {
        title: "Stock Product",
        path: "/stockall",
        icon: icon("ic_inventory"),
      }
    ]
  }
];
