import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { apiURL } from "../config/Constants";
import statesData from '../_mock/state.json';
export const Context = createContext();

const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState([]);
  const [isLogin, setLogin] = useState(false);
  const [isLoading, setIsLoding] = useState(false);
  const [employeeList, setemployeeList] = useState([]);
  const [units, setUnint] = useState([]);
  const [products, setProducts] = useState([]);
  const [INVproducts, setINVProducts] = useState([]);
  const [INVRproducts, setINVRProducts] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [invoices,setInvoices ] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [payments, setPayments] = useState([]);
  const [paymentModes, setPaymentModes] = useState([]);
  const [states, setStates] = useState(statesData.states);
  const [gstPer,setGstPer]=useState(0);
  const islogin = () => {
    setIsLoding(true);
    const userInfo = JSON.parse(localStorage.getItem("User")) || "";
    console.log(userInfo);
    if (userInfo != "") {
      const userData = userInfo;
      setUser(userData);
      setLogin(true);
    }
    setIsLoding(false);
  };
  const loginAPI = async (email, password) => {
    setIsLoding(true);
    await axios
      .post(`${apiURL}/userloginAPI`, {
        email,
        password,
      })
      .then((res) => {
        if (res.data.status === 200) {
          const userData = res.data.data;
          localStorage.setItem("User", JSON.stringify(userData));
          setUser(userData);
          setLogin(true);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Login Sucessfully",
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (res.data.status === 405) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Wait for Admin Approval",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Login Failed",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.log("Login Error", err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Contact Admin to Login (Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });

    setIsLoding(false);
  };
  const logout = async () => {
    setIsLoding(true);
    await axios
      .post(`${apiURL}/userlogoutAPI`, {
        id: user.id,
      })
      .then((res) => {
        if (res.data.status === 200) {
          localStorage.clear();
          setLogin(false);
          setUser([]);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Logout Sucessfully",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Logout Failed",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.log("Login Error", err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Contact Admin to Login (Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });

    setIsLoding(false);
  };

  const signUpAPI = async (values, callback) => {
    setIsLoding(true);
    await axios
      .post(`${apiURL}/userlogupAPI`, {
        name: values.fname,
        address: values.address,
        mob: values.mob,
        email: values.email,
        password: values.password,
        aadhar: values.aadhar,
      })
      .then((res) => {
        if (res.data.status === 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Submit Sucessfully",
            text: "Wait for Admin Approval",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Email Id already exist!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Contact Admin to Login (Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });

    callback();
    setIsLoding(false);
  };

  const getEmployee = async () => {
    setIsLoding(true);
    verifyUserState();
    await axios
      .get(`${apiURL}/employeeAPI`)
      .then((res) => {
        setemployeeList(res.data.employeeList);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoding(false);
  };

  const stateUpdate = async (id, expired_status) => {
    setIsLoding(true);
    verifyUserState();
    await axios
      .post(`${apiURL}/stateUpdateAPI`, {
        id,
        expired_status,
      })
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Sucessfully Update",
          showConfirmButton: false,
          timer: 3000,
        });
        getEmployee();
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Error",
          showConfirmButton: false,
          timer: 3000,
        });
      });
    setIsLoding(false);
    setIsLoding(false);
  };

  const getProducts = async (callback) => {
    setIsLoding(true);
    verifyUserState();
    await axios
      .get(`${apiURL}/productsAPI`)
      .then((res) => {
        setProducts(res.data.products);
        callback(res.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoding(false);
  };

  const configCatcher = async () => {
    setIsLoding(true);
    await axios
      .get(`${apiURL}/configAPI`)
      .then((res) => {
        setUnint(res.data.units);
        setGstPer(res.data.gst_percentage);
        setPaymentModes(res.data.modes);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoding(false);
  };

  const unitProvider = (unit) => {
    let Unit = 1000 * parseFloat(unit);
    if (Unit >= 1000) {
      Unit = Unit / 1000;
      return Unit.toFixed(2) + "kg";
    }
    return Unit.toFixed(2) + "gm";
  };

  const updateProductAPI= async (values,callback,callback2)=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .post(`${apiURL}/updateProductAPI`, values)
      .then((res) => {
        if (res.data.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Product Update Sucessfully",
            showConfirmButton: false,
            timer: 3000,
          });

        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Failed!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Contact Admin  (Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });
      getProducts(callback);
      callback2();
      setIsLoding(false);
  }

  const addProductAPI= async (values,callback)=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .post(`${apiURL}/addProductAPI`, values)
      .then((res) => {
        if (res.data.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Product Added Sucessfully",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Failed!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Contact Admin to Login (Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });
      callback();
    setIsLoding(false);
  }

  const getInventory= async(callback=(data)=>{})=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .get(`${apiURL}/inventoryAPI`)
      .then((res) => {
        setInventory(res.data.inventory);
        callback(res.data.inventory);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoding(false);
  }

  const addInventoryAPI= async (values,callback)=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .post(`${apiURL}/addInventoryAPI`, values)
      .then((res) => {
        if (res.data.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Inventory Added Sucessfully",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Failed!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Contact Admin to Login (Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });
      callback();
    setIsLoding(false);
  }

  const deleteInventoryAPI= async(id,callback)=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .post(`${apiURL}/deleteInventoryAPI`, {
        id:id
      })
      .then((res) => {
        if (res.data.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Inventory Delete Sucessfully",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Failed!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Contact Admin to Login (Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });
      callback();
    setIsLoding(false);
  }

  const getCustomer= async(callback=(res)=>{

  })=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .get(`${apiURL}/customerAPI`)
      .then((res) => {
        let response=res.data.customers;
        // if(user.user_type != 1){
        // response=response.filter((item)=>item.emp_id == user.id );
        
        // }
        setCustomers(response);
        callback(response); 
      })
      .catch((err) => {
        console.log(err);
      }); 
    setIsLoding(false);
  }

  const addCustomerAPI=async(values,callback)=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .post(`${apiURL}/addCustomerAPI`, values)
      .then((res) => {
        if (res.data.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Customer Added Sucessfully",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Failed!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Contact Admin(Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });
      callback();
    setIsLoding(false);
  }
  const updateCustomerAPI= async (values,callback)=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .post(`${apiURL}/updateCustomerAPI`, values)
      .then((res) => {
        if (res.data.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Customer Update Sucessfully",
            showConfirmButton: false,
            timer: 3000,
          });

        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Failed!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Contact Admin (Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });
      callback();
      setIsLoding(false);
  }
  const addInvoiceAPI=async(values,callback)=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .post(`${apiURL}/addInvoiceAPI`, values)
      .then((res) => {
        if (res.data.status == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Invoice Added Sucessfully",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Failed!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Contact Admin(Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });
      callback();
    setIsLoding(false);
  }
  const invoicesAPI=async(callback=(e)=>{

  })=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .get(`${apiURL}/invoicesAPI`)
      .then((res) => {
        let response=res.data.invoiceList;
        // if(user.user_type != 1){
        // response=response.filter((item)=>item.emp_id == user.id );
        // }
        setInvoices(response);
        callback(response);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoding(false);
  }

  const getProductsInvoiceAPI= async (id)=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .post(`${apiURL}/getProductsInvoiceAPI`, { id:id})
      .then((res) => {
        setINVProducts(res.data.products)
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoding(false);
  }

  const paymentsAPI= async()=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .get(`${apiURL}/paymentsAPIUpdated`)
      .then((res) => {
        let response=res.data.payments;
        // if(user.user_type != 1){
        // response=response.filter((item)=>{
        //   let array=customers.filter((i)=>i.c_id == item.payment_customer_id);
        //   if(array.length > 0){
        //     return item;
        //   }
        //  });
        // }
        setPayments(response);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoding(false);
  }

  const addpaymentsAPI= async(values,callback)=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .post(`${apiURL}/addPaymentAPIUpdated`, values)
      .then((res) => {
        if (res.data.status == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Payment Added Sucessfully",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Failed!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Contact Admin(Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });
      callback();
    setIsLoding(false);

  }

  const returnInvoiceAPI=async (values)=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .post(`${apiURL}/invoiceReturnAPI`, values)
      .then((res) => {
        if (res.data.status == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Invoice Return Sucessfully",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Failed!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Contact Admin(Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });
      
    setIsLoding(false);
  }

  const getReturnProducts= async ()=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .get(`${apiURL}/returnProductsAPI`)
      .then((res) => {
        setINVRProducts(res.data.products)
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoding(false);
  }
  // verifiedCheckerAPI
  const verifyUserState=async ()=>{
    setIsLoding(true);
    await axios
      .post(`${apiURL}/verifiedCheckerAPI`,{
        employee_id:user.employee_id
      })
      .then((res) => {
        let response=res.data.verified;
        if(!response){
          logout();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoding(false);
  }
  // updateUserAPI
  const updateUserAPI= async (values, callback)=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .post(`${apiURL}/updateUserAPI`,{
        id:values.id,
        name: values.fname,
        address: values.address,
        mob: values.mob,
        email: values.email,
        password: values.password,
        aadhar: values.aadhar,
      })
      .then((res) => {
        if (res.data.status === 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Update Sucessfully",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Failed To Update!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Contact Admin to Login (Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });

    callback();
    setIsLoding(false);
  }

  //deleteInvoiceAPI
  const deleteInvoiceAPI=async(values,callback)=>{
    setIsLoding(true);
    verifyUserState();
    await axios
      .post(`${apiURL}/deleteInvoiceAPI`, values)
      .then((res) => {
        if (res.data.status == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Delete Sucessfully",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Failed!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Contact Admin(Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });
      callback();
    setIsLoding(false);

  }

  const printedStatusAPI=async (id,callback)=>{
   console.log(id);
    await axios.post(`${apiURL}/printedInvoice`,{
      invoice_id:id
    }).then((res)=>{
        console.log(res);
        callback();
    }).catch((err)=>{
      console.log(err);
    })
    
  }

  const stockReset=async(callback)=>{
    setIsLoding(true);
    await axios
      .post(`${apiURL}/stockReset`, {access:1})
      .then((res) => {
        if (res.data.status == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Stock Reset Sucessfully",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Failed!!!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Contact Admin(Failed)",
          showConfirmButton: false,
          timer: 1500,
        });
      });
      callback();
    setIsLoding(false);
  }

  useEffect(() => {
    islogin();
    configCatcher();
    verifyUserState();
  }, []);

  return (
    <Context.Provider
      value={{
        printedStatusAPI,
        user,
        isLogin,
        isLoading,
        loginAPI,
        logout,
        signUpAPI,
        getEmployee,
        employeeList,
        stateUpdate,
        units,
        products,
        getProducts,
        unitProvider,
        addProductAPI,
        updateProductAPI,
        inventory,
        getInventory,
        addInventoryAPI,
        deleteInventoryAPI,
        customers,
        getCustomer,
        addCustomerAPI,
        updateCustomerAPI,
        states,
        addInvoiceAPI,
        gstPer,
        configCatcher,
        invoicesAPI,
        invoices,
        getProductsInvoiceAPI,
        INVproducts,
        paymentsAPI,
        payments,
        paymentModes,
        addpaymentsAPI,
        returnInvoiceAPI,
        getReturnProducts,
        INVRproducts,
        updateUserAPI,
        deleteInvoiceAPI,
        stockReset
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContextProvider;
