import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useContext, useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Autocomplete,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { useNavigate } from 'react-router-dom';
import InvoicePDFTemp from './InvoicePDFTemp';
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'payment_date', label: 'Payment Date', alignRight: false },
  // { id: 'invoice_no', label: 'Invoice No', alignRight: false },
  // { id: 'emp_id', label: 'Employee ID', alignRight: false },
  { id: 'c_name', label: 'Customer Name', alignRight: false },
  { id: 'payment_paid', label: 'Payment Paid', alignRight: false },
  // { id: 'payment_unpaid', label: 'Balance', alignRight: false },
  { id: 'payment_mode', label: 'Payment Mode', alignRight: false },
  // { id: 'payment_transaction', label: 'Transaction ID', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.payment_cus_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DailyPayment({context}) {
  const [add, openAdd] = useState(true);
  const [open, setOpen] = useState(null);
  const {addpaymentsAPI,paymentModes,payments,paymentsAPI,invoices,invoicesAPI, employeeList,getEmployee,user,stateUpdate,getProductsInvoiceAPI,customers,getCustomer }=useContext(context);
  const navigate=useNavigate();
  useEffect(()=>{
    getEmployee()
    invoicesAPI()
    paymentsAPI()
    getProductsInvoiceAPI("");
    getCustomer();
  },[])
  const updateList= async ()=>{
    await getEmployee();
    await paymentsAPI();
    await invoicesAPI();
  }
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [userID,setuserId]=useState([]);

  const handleOpenMenu = (event,id) => {
    setuserId(id);
    setOpen(event.currentTarget);
    
  };

  const handleCloseMenu = () => {
    setuserId([]);
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = payments.map((n) => n.full_name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - payments.length) : 0;

  const filteredUsers = applySortFilter(payments, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  let currentDate = new Date().toJSON().slice(0, 10);

  const BFCalculater = (inv) => {
    let bf = 0;
    inv.map((item) => {
      bf = bf + (parseFloat(item.total)-parseFloat(item.paid_amount));
    });
    return bf.toFixed(2);
  };

  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  const [customerID, setcustomerID] = useState(0);
  const [customers90, setcustomers90] = useState([]);

  let ACustomer = [];
  customers.map((item) => {
    ACustomer.push({ label: item.c_name +"/"+item.address_line1+"/"+item.town, value: item.c_id });
  });

  ACustomer = ACustomer.sort(function (a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <Helmet>
        <title> Invoice Payments  | Shree Smile </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Invoice Payments 
          </Typography>
          {/* {user.user_type != 1?(<></>):( <Button
            variant="contained"
            startIcon={<Iconify icon={add ? "ic:baseline-close" : "mdi:add"} />}
            onClick={() => {
              openAdd(!add);
            }}
          >
            {add ? "Close" : "Add Payment"}
          </Button>)} */}
         
        </Stack>
        {user.user_type != 1 ?  (
          <></>
        ):(
          <Paper elevation={2} sx={{ padding: 3, marginBottom: 5 }}>
            <Formik
              initialValues={{ unpaidA:"",date: currentDate,invoice_id:"",paid_amount:"",payment_mode:"CASH",transaction_id:"null",emp_id:user.id }}
              validate={(values)=>{
                const errors={};
                if(parseInt(values.qty) <= 0){
                    errors.qty="Qunatity Always > 0";
                }
                if(values.product == ""){
                    errors.product="Required";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                if(parseFloat(values.paid_amount) <= parseFloat(values.unpaidA) ){
                if(customerID !=="" && values.paid_amount !=="" && values.payment_mode !=="" ){
                    // Swal.fire({
                    //     position: "center",
                    //     icon: "warning",
                    //     title: "Warning",
                    //     text:`Notice: Ensure that all Details are Correct , The Action Did not changable.`,   
                    //     showConfirmButton: true,
                        
                    //   }).then((result) => {
                        addpaymentsAPI({
                          customer_id:customerID,
                          paid_amount:values.paid_amount,
                          payment_mode:values.payment_mode
                        },()=>{
                          updateList();  
                        });
                        openAdd(false);
                                          //  })
                }else{
                    Swal.fire({
                        position: "center",
                        icon: "warning",
                        title: "Filed Missing",
                        text:`Note: If there is No Transaction Details Put NULL`,   
                        showConfirmButton: false,
                        timer: 2000,
                        
                      }) 
                }
              } else{
                alert("The Amount is High");
              }

              setcustomerID(0);
              setcustomers90([]);
              resetForm();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
                /* and other goodies */
              }) => (
                <>
                  <Typography variant="h6" gutterBottom>
                    Payment Details
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      padding: 5,
                      justifyContent:"space-evenly",
                      flexWrap: "wrap",
                      marginBottom: 5,
                      marginTop: 5,
                      gap: 5,
                    }}
                  >
                    <TextField 
                    disabled
                     name="date"
                     type="date" 
                     label="Date"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.date}
                     required
                     />
                     <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={customers90}
                    options={ACustomer}
                    name="name"
                    onChange={(event, newValue) => {
                      setcustomers90(newValue);
                      if (newValue != null) {
                        let value = newValue.value;
                        setcustomerID(newValue.value);
                        const inv = invoices.filter((item) => {
                          let invoiceDate = new Date(item.created_at);
                          let Date9 = new Date(values.date);
                          const Date89=addDays(Date9, 1);
                          
                          return (
                            item.customer_id === value &&
                            invoiceDate < Date89 &&
                            item.payment_paid === "0"
                          );
                        });
                        const bf = BFCalculater(inv);
                        setFieldValue("unpaidA", bf);
                      } else {
                        setFieldValue("unpaidA", "");
                        setcustomerID(0);
                      }
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Customer Name" />
                    )}
                  />
                    {/* <FormControl sx={{ minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-label">
                        Invoice No
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Invoice No"
                        name="invoice_id"
                        onChange={(v)=>{handleChange(v);
                        const obj=invoices.filter((item)=>item.invoice_id == v.target.value)[0];
                        const val=parseFloat(obj.total) - parseFloat(obj.paid_amount);
                        setFieldValue("unpaidA",val);
                        }}
                        onBlur={handleBlur}
                        value={values.invoice_id}
                        required
                      >
                        {invoices.filter((item)=>item.payment_paid != "1").map((item) => (
                          <MenuItem value={item.invoice_id }>
                            {"#SSU-"+item.invoice_no}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    <TextField
                       disabled
                    name="unpaidA"
                      label="Unpaid Amount"
                      type="text"
                      onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.unpaidA}
                       
                    />
                    <TextField 
                     name="paid_amount"
                     type="number" 
                     label="Amount"
                     onChange={
                            handleChange
                          }
                     onBlur={handleBlur}
                     value={values.paid_amount}
                     required
                     />
                      {/* <FormControl sx={{ minWidth: 160 }} required>
                      <InputLabel id="demo-simple-select-label2">
                        Payment Mode
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label2"
                        id="demo-simple-select"
                        label="Invoice No"
                        name="payment_mode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.payment_mode}
                        required
                      >
                        {paymentModes.map((item) => (
                          <MenuItem value={item.mode} selected>
                          {item.mode}
                        </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    {/* <TextField
                    name="transaction_id"
                      label="Transaction Details"
                      type="text"
                      onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.transaction_id}
                       required
                       /> */}
                  </Box>
                  <Stack spacing={1}>
                 <Typography> {errors.qty && touched.qty && errors.qty}</Typography>
                 <Typography> {errors.product && touched.product && errors.product}</Typography>
                  </Stack>
                  <LoadingButton size="small" type="submit" variant="contained" onClick={handleSubmit} >
                    Final Save
                  </LoadingButton>
                </>
              )}
            </Formik>
          </Paper>
        )}

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={payments.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    
                    const selectedUser = selected.indexOf(row.payment_cus_id  ) !== -1;
                    return (
                      <TableRow hover key={row.payment_cus_id  } tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event,row.payment_cus_id   )} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {row.payment_cus_created}
                            </Typography>
                          </Stack>
                        </TableCell>

                        {/* <TableCell align="left">{"#SSU-"+row.invoice_no}</TableCell> */}
                        
                        {/* <TableCell align="left">{row.employee_id}/{row.full_name}</TableCell> */}
                        <TableCell align="left">{row.payment_cus_name}</TableCell>
                        <TableCell align="left">{row.payment_cus_paid_amount}</TableCell>
                        {/* <TableCell align="left">{row.payment_cus_balance}</TableCell> */}
                        <TableCell align="left">{row.payment_cus_payment_mode}</TableCell>
                        {/* <TableCell align="left">{row.payment_transaction}</TableCell> */}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25,50,100]}
            component="div"
            count={payments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={()=>{
         handleOpenModal();
        }}>
          <Iconify icon={'mdi:download'} sx={{ mr: 2 }} />
          Download
        </MenuItem>
      </Popover>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
            width:150,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 24,
            background:"white",
            borderRadius:3,
            padding:2
            }}>
        <InvoicePDFTemp data={userID} context={context} />
        </Box>
      </Modal>
    </>
  );
}
