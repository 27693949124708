import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Svg,
  Line,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import PDFTABLE, { priceProvider } from "./PDFTABLE";
import { useContext, useEffect, useState } from "react";
import CPDF from "./CPDF";


// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    margin: 20,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: "100%",
  },
});
Font.register({
  family: 'Open Sans',
  fonts: [
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
  });
// Create Document Component
function InvoicePDFTemp({ data, context }) {
  const {
    getReturnProducts,
    INVRproducts,
    INVproducts,
    getProductsInvoiceAPI,
    unitProvider,
  } = useContext(context);
  useEffect(() => {
    console.log(data);
  });


  const [sgst,setSGST]=useState(0);
  const [cgst,setCGST]=useState(0);
  const [igst,setIGST]=useState(0);
  
  return (
    <>
      {/* <PDFViewer style={styles.viewer}> */}
        {/* Start of the document*/}
        <PDFDownloadLink 
        document={
        <Document title={"#SSU" + data.invoice_no}>
          {/*render a single page*/}
          <Page size="A4" style={styles.page}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 0,
                marginRight: 10,
              }}
            >
              <View style={{  }}>
                {/* <Text style={{ fontSize: 18, fontWeight: "black" }}>
                  REPORT
                </Text> */}
                <View style={{ marginTop: 0,flexDirection:'row' }}>
                  <View style={{ padding: 3, borderWidth: 1,width:150,padding:5 }}>
                    <Text style={{ fontSize: 12, fontWeight: "black" }}>
                      {data.name}
                    </Text>
                    <TextBox
                      lable="Address"
                      value={data.address_line1 +"," + data.town+ "," + data.c_state+""}
                    />
                    <TextBox lable="Phone No" value={"+91" + data.phone} />
                  </View>
                  <View style={{ padding: 3, borderWidth: 1,width:120 }}>
                    <TextBox
                      lable="Invoice ID"
                      value={"#SSU-" + data.invoice_no}
                    />
                    <TextBox lable="Date" value={data.created_date} />
                  </View>
                  <View style={{ padding: 3, borderWidth: 1,width:120}}>
                <Text style={{ fontSize: 8, fontWeight: "black" }}>
                 M/S SHREE SHAKTI UDYOG
                </Text>
                <TextBox lable="GSIN" value="21BBAPB7939M1ZO" />
                <Text style={{ fontSize: 12, fontWeight: "black" }}>
                 MOB: 8249239051
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    fontWeight: "black",
                    color: "gray",
                    marginTop: 4,
                  }}
                >
                Markona Balasore Odisha
                </Text>
                <Text
                  style={{ fontSize: 8, fontWeight: "black", color: "gray" }}
                >
                  Pin-756126
                </Text>
               
                {/* <TextBox lable="Salesman" value={data.full_name} /> */}
                  </View>
                  {/* <TextBox lable="Due Date" value={data.due_date}/> */}
                </View>
              </View>
              <View style={{  width:180, alignItems: "center",justifyContent:"center" }}>
                <Image style={{ width: 80, height: 60 }} src="logo.png" />
              
              </View>
            </View>
            {/* <Linee/> */}
            {/* <View style={{display: "flex", flexDirection: "row",marginTop:10,marginRight:10,marginBottom:6}}>
            <View style={{flex:1}}>
                <TextBoxUpgade 
                lable="Billing Address"
                value={data.billing_address}/>
            </View>
            <View style={{flex:1}}>
            <TextBoxUpgade 
                lable="Shipping Address"
                value={data.shipping_address}/>
            </View>
          </View> */}
            {/* <Linee style={{width:520}} /> */}
            <PDFTABLE
              returns={INVRproducts.filter(
                (item) => item.invoice_id === data.invoice_id
              )}
              products={INVproducts.filter(
                (item) => item.invoice_id === data.invoice_id
              )}
              data={data}
              unitP={unitProvider}
            />
            <View style={{flexDirection:'row',marginTop:0}} >
              <View>
           <CPDF
             products={INVproducts.filter(
              (item) => item.invoice_id === data.invoice_id
            )}
            data={data}
            callback={(obj)=>{
                setIGST(obj.igst);
                setCGST(obj.cgst);
                setSGST(obj.sgst);
            }}
            />
             {
              INVRproducts.filter(
                (item) => item.invoice_id === data.invoice_id
              ).length > 0 ?(
                <>
                <Text>Return GST</Text>
                   <CPDF
             products={INVRproducts.filter(
              (item) => item.invoice_id === data.invoice_id
            )}
            data={data}
            callback={(obj)=>{
              setIGST(igst-obj.igst);
              setCGST(cgst-obj.cgst);
              setSGST(sgst-obj.sgst);
          }}
            />
                </>
              ):(<></>)
            }
               <View style={{marginTop:5,gap:5,width:370}}>
          <Text style={{fontSize: 12, fontWeight: "black"}}>Term and Conditions</Text> 
           <Text style={{fontSize: 10, fontWeight: "black"}}>1.Check first before receiving the product.No complane for shortage.</Text>
           <Text style={{fontSize: 10, fontWeight: "black"}}>2.Return damaged product within 90 days from the date of manufacturing. Replacement will be within 60 days.</Text>
          </View>
          </View>
        <View style={{width:170,padding:1,marginTop:0,borderWidth:1,paddingLeft:10}}>
           
           {data.c_state == "Odisha"?(<>
             <TextBox style={ {fontFamily: 'Open Sans',fontWeight:600,fontSize:10}} lable="Sub Total" margin={1} value={(parseFloat(data.sub_total)-(parseFloat(cgst)*2)).toFixed(2)} />
             <TextBox style={ {fontFamily: 'Open Sans',fontWeight:600,fontSize:10}} lable="CGST Total" margin={1} value={parseFloat(cgst).toFixed(2)} />
             <TextBox style={ {fontFamily: 'Open Sans',fontWeight:600,fontSize:10}} lable="SGST Total" margin={1} value={parseFloat(sgst).toFixed(2)} />
           </>):(<>
             <TextBox style={ {fontFamily: 'Open Sans',fontWeight:600,fontSize:10}} lable="Sub Total" margin={1} value={(parseFloat(data.sub_total)-parseFloat(igst)).toFixed(2)} />
             <TextBox style={ {fontFamily: 'Open Sans',fontWeight:600,fontSize:10}}lable="IGST Total" margin={1} value={parseFloat(igst).toFixed(2)} />
           </>)}
           <TextBox style={ {fontFamily: 'Open Sans',fontWeight:600,fontSize:10}} lable="Discount" margin={1} value={priceProvider(data.discount)} />
           <TextBox style={ {fontFamily: 'Open Sans',fontWeight:600,fontSize:10}}lable="Total" margin={1} value={priceProvider(data.total)} />
           <TextBox style={ {fontFamily: 'Open Sans',fontWeight:600,fontSize:10}} lable="BF" margin={1}  value={priceProvider(data.invoice_last_bf)} />
            <View style={{width:"100%",borderTopWidth:1}}>
           <TextBox style={ {fontFamily: 'Open Sans',fontWeight:600,fontSize:10}}lable="Grand Total" margin={1} value={(parseFloat(data.total)+parseFloat(data.invoice_last_bf)).toFixed(2)} />
           </View>
           </View>
           </View>
           
       
          </Page>
        </Document>
          }
        fileName={"#SSU"+data.invoice_no}>
            {({ loading }) =>
                loading ? "Loading...": "Download PDF"
              }
        </PDFDownloadLink>  
      {/* </PDFViewer> */}
    </>
  );
}
export default InvoicePDFTemp;

const TextBox = ({ lable, value,margin=4 ,style={}}) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: margin,
        marginBottom: margin,
      }}
    >
      <Text style={[{ fontSize: 8, fontWeight: "black", color: "black" },style]}>
        {lable}:{" "}
      </Text>
      <Text style={[{ fontSize: 8, fontWeight: "black" },style]}>{value}</Text>
    </View>
  );
};

const TextBoxUpgade = ({ lable, value }) => {
  return (
    <View>
      <Text
        style={{
          fontSize: 8,
          fontWeight: "black",
          color: "gray",
          marginBottom: 2,
        }}
      >
        {lable}:{" "}
      </Text>
      <Text style={{ fontSize: 8, fontWeight: "black" }}>{value}</Text>
    </View>
  );
};

const Linee = ({Width="495",Height="1"}) => {
  return (
    <Svg height={Height} width={Width}>
      <Line x1="0" y1="5" x2="380" y2="5" strokeWidth={2} stroke="rgb(0,0,0)" />
    </Svg>
  );
};
