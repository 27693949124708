import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material'

const Container=styled("div")({
})
const Table=styled("table")({
    border:"1px solid black",
    borderCollapse:"collapse",
    width:"100%",
})
const THead=styled("th")({
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:14,
    fontWeight:"bold"
})

const TData=styled("td")({
    // padding:2,
    border:"1px solid black",
    borderCollapse:"collapse",
    fontSize:14,
    fontWeight:"bold"
})

const PRequiremtTable2 = ({inv,allInvoiceProduct,unit,productData}) => {


  return (
    <Container>
    <Table>
      <thead>
          <tr>
              <THead>#</THead>
              <THead>PRODUCT NAME</THead>
              <THead>QUANTITY</THead>
              <THead>REMARK</THead>
          </tr>
      </thead>
      <tbody>
          {
              productData.map((item,i)=>(
                  <tr className='page-break'>
                       <TData style={{textAlign: "center"}}>{i+1}</TData>
                      <TData style={{textAlign: "center"}}> {unit(item.product.weight)+" X "+item.product.product_name}</TData>
                      <TData style={{textAlign: "center"}}>{item.qty}</TData>
                      <TData style={{textAlign: "center"}}></TData>
                  </tr>
              ))
          }
      </tbody>
    </Table>
  </Container>
  )
}

export default PRequiremtTable2
